import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/popups/informations/notificationpopup.css";
import TableIcon from "../../../assets/icons/jsxIcons/TableIcon";
import UploadIcon from "../../../assets/icons/jsxIcons/UploadIcon";
import KmeansIcon from "../../../assets/icons/jsxIcons/KmeansIcon";
import DecisionTreeIcon from "../../../assets/icons/jsxIcons/DecisionTreeIcon";
import RandomForestIcon from "../../../assets/icons/jsxIcons/RandomForestIcon";
import LinearRegressionIcon from "../../../assets/icons/jsxIcons/LinearRegressionIcon";
import AlertIcon from "../../../assets/icons/jsxIcons/AlertIcon";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";

import taskService from "../../../services/TaskService";
import dp from "../../../helpers/data_parse";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const NotificationPopup = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const [notificationList, setNotificationList] = useState([]);
   const link = "notificationquickstart.notification";

   const getNotifications = useCallback(() => {
      taskService
         .getusertasks(localStorage.getItem("user_token"))
         .then((res) => {
            if (res) {
               const notifications = res
                  .filter((element) => !element.type.includes("histogram"))
                  .map((element) => ({
                     Nome: element.name || "-",
                     Algorithm: element.algorithm || "-",
                     Projeto: element.project || "-",
                     Dataset: element.dataset || "-",
                     TaskType: element.type ? element.type : "-",
                     Data: element.date ? dp.parseDate(element.date) : "-",
                     Status: element.status,
                  }));
               setNotificationList(notifications);
            }
         })
         .catch((error) => handleErrorResponse(history, error, t));
   }, [history, t]);

   useEffect(() => {
      getNotifications();
   }, [getNotifications]);

   const renderIcon = (nome) => {
      switch (nome) {
         case "-":
            return <UploadIcon style={{ color: "#38A3A5", size: "16" }} />;
         case "kmeans":
            return <KmeansIcon style={{ color: "#38A3A5", size: "16" }} />;
         case "decisiontree":
            return <DecisionTreeIcon style={{ color: "#38A3A5", size: "16" }} />;
         case "randomforest":
            return <RandomForestIcon style={{ color: "#38A3A5", size: "16" }} />;
         case "linearregression":
            return <LinearRegressionIcon style={{ color: "#38A3A5", size: "16" }} />;
         default:
            return <TableIcon style={{ color: "#38A3A5", size: "16" }} />;
      }
   };

   return (
      <div className="notification-popup">
         {notificationList.length === 0 ? (
            <div className="alert-icon">
               <AlertIcon />
               <p>{t(`${link}.default`)}</p>
            </div>
         ) : (
            <div>
               {notificationList.map((data, index) => (
                  <div key={index} className="notification-item">
                     <span className="ni--header">
                        <div className="ni--header--icon">{renderIcon(data.Algorithm)}</div>
                        <div className="ni--header--title">
                           {data.Algorithm === "-" ? t(`${link}.dataset`) : t(`${link}.model`)}
                        </div>
                     </span>
                     <div className="ni--body">
                        <div className="ni--body--name">{data.Nome === "-" ? data.Dataset : data.Nome}</div>
                        <div className="ni--body--project">{data.Projeto}</div>
                     </div>
                     <div className="ni--footer">
                        <div className="ni--footer--date">{data.Data}</div>
                     </div>
                  </div>
               ))}
            </div>
         )}
      </div>
   );
};
