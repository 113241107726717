import React, { useState } from "react";
import { GridColumnMenu } from "@material-ui/data-grid";
import { MenuItem } from "@material-ui/core";

import { FaRegTrashAlt } from "react-icons/fa";
import { VscReplace } from "react-icons/vsc";
import FillDialog from "./components/FillDialog";
import DeleteDialog from "./components/DeleteDialog";
import { useTranslation } from "react-i18next";

const CustomColumnMenu = (props) => {
   const { currentColumn } = props;
   const [fillDialogOpen, setFillDialogOpen] = useState(false);
   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

   const { t } = useTranslation();

   const handleOpenFillDialog = (event) => {
      event.stopPropagation();
      setFillDialogOpen(true);
   };

   const handleCloseFillDialog = () => {
      setFillDialogOpen(false);
   };

   const handleOpenDeleteDialog = (event) => {
      event.stopPropagation();
      setDeleteDialogOpen(true);
   };

   const handleCloseDeleteDialog = () => {
      setDeleteDialogOpen(false);
   };

   return (
      <>
         <GridColumnMenu {...props} />

         <>
            <MenuItem
               onClick={handleOpenFillDialog}
               style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
               }}
            >
               {t("dataset.content.table.columnMenu.fill.name")} <VscReplace />
            </MenuItem>
            <MenuItem
               onClick={handleOpenDeleteDialog}
               style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
               }}
            >
               {t("dataset.content.table.columnMenu.delete.name")} <FaRegTrashAlt />
            </MenuItem>
            <FillDialog open={fillDialogOpen} onClose={handleCloseFillDialog} currentColumn={currentColumn} />
            <DeleteDialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog} currentColumn={currentColumn} />
         </>
      </>
   );
};

export default CustomColumnMenu;
