import * as d3 from "d3";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import colorPalette from "../../assets/data/colorPalette.json";

export const DecisionTreeD3 = ({ result, fieldList, setCaminhoAtual }) => {
   const [currentData, setCurrentData] = useState(result);
   const d3Chart = useRef();
   const currentNode = useRef();

   const {
      models: modelColors,
      decision: { predictTrue: predictTrueColor, predictFalse: predictFalseColor },
   } = colorPalette;
   const { t } = useTranslation();

   // Set node color based on KPI
   const DecisionTreeKPI = useCallback(
      (node) => {
         if (!node.data.Feature) {
            return node.data.Predict === "1.0" || node.data.Predict === 1 ? predictTrueColor : predictFalseColor;
         }
         if (fieldList) {
            const fieldIndex = fieldList.indexOf(node.data.Feature);
            if (fieldIndex >= 0) {
               return modelColors[fieldIndex % modelColors.length];
            }
         }
         return "#404040";
      },
      [fieldList, modelColors, predictFalseColor, predictTrueColor],
   );

   // Tooltip display on mouseover
   const handleMouseOver = useCallback(
      (event, node) => {
         const tooltip = d3.select(".d3-tooltip");
         const feature = node.data.Feature || `Predict: ${node.data.Predict}`;
         const dataPoints = node.data.DataPoints
            ? `<strong>${node.data.DataPoints} ${t("model.tooltip_tree.instances")}</strong>`
            : "";
         const percentage = node.data.Percentage
            ? `<strong>${node.data.Percentage}% ${t("model.tooltip_tree.of_data")}</strong>`
            : "";

         tooltip
            .style("opacity", 1)
            .html(
               `
               <div style="padding: 8px; font-size: 14px; color: white;">
                  <h3 style="margin: 0 0 4px;">${feature}</h3>
                  <div>${dataPoints}</div>
                  <div>${percentage}</div>
               </div>
            `,
            )
            .style("left", `${event.pageX + 15}px`)
            .style("top", `${event.pageY + 15}px`)
            .style("background", "rgba(0, 0, 0, 0.75)")
            .style("border-radius", "8px")
            .style("box-shadow", "0px 4px 12px rgba(0, 0, 0, 0.2)")
            .style("pointer-events", "none");

         const currentNodePath = node
            .ancestors()
            .map((ancestor) => ({
               Feature: ancestor.data.Feature || "Predict",
               Threshold: ancestor.data.Threshold || ancestor.data.Predict,
               Operation: ancestor.data.Operation,
               Side: ancestor.data.Side,
            }))
            .reverse();

         setCaminhoAtual(currentNodePath);
         d3.select(event.target).attr("fill", "#333").attr("stroke", "#aaa").attr("stroke-width", 3);
         currentNode.current = event.target;
      },
      [setCaminhoAtual, t],
   );

   const handleMouseOut = useCallback(
      (event, node) => {
         d3.select(".d3-tooltip").style("opacity", 0);
         d3.select(event.target)
            .attr("fill", DecisionTreeKPI(node))
            .attr("stroke", "transparent")
            .attr("stroke-width", 0);
      },
      [DecisionTreeKPI],
   );

   // Convert node to a specific format recursively
   const recursiveNodeConvert = useCallback((node, ancestors) => {
      if (!node.children) return { Predict: node.data.Predict };
      return {
         children: node.children
            .filter((child) => ancestors.includes(child.parent))
            .map((child) => recursiveNodeConvert(child, ancestors)),
         Operation: node.data.Operation,
         Feature: node.data.Feature,
         Threshold: node.data.Threshold,
         Side: node.data.Side,
      };
   }, []);

   const handleClick = useCallback(
      (target, ancestors) => {
         const targetNode = target.__data__;
         const rootNode = ancestors[ancestors.length - 1];
         if (targetNode === rootNode || currentData !== result) {
            setCurrentData(result);
         } else {
            const selectedNodePath = recursiveNodeConvert(rootNode, ancestors);
            setCurrentData(selectedNodePath);
         }
      },
      [currentData, recursiveNodeConvert, result],
   );

   const handleEvent = useCallback(
      (event) => {
         const ancestors = event.target.__data__.ancestors();
         event.type === "click" ? handleClick(event.target, ancestors) : handleMouseOver(event, ancestors);
      },
      [handleClick, handleMouseOver],
   );

   // Draw the decision tree
   const desenharDecisionTree = useCallback(() => {
      if (!currentData) return;

      d3.selectAll("#decisiontree-graph, #decisiontree-links, #decisiontree-nodes").remove();

      const margin = { top: -55, right: 10, bottom: 90, left: -30 };
      const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
      const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;

      const svg = d3
         .select(d3Chart.current)
         .attr("viewBox", `0 0 ${width} ${height}`)
         .attr("preserveAspectRatio", "xMidYMid meet")
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      const graph = svg.append("g").attr("id", "decisiontree-graph").attr("transform", "translate(50,50)");
      const hierarchy = d3.hierarchy(currentData);
      const tree = d3.tree().size([width - 40, height]);
      const myTree = tree(hierarchy);

      graph
         .selectAll(".link")
         .data(myTree.links())
         .enter()
         .append("path")
         .attr("id", "decisiontree-links")
         .attr("class", "link")
         .attr("fill", "none")
         .attr("stroke", "#aaa")
         .attr("stroke-width", 2)
         .attr(
            "d",
            d3
               .linkVertical()
               .x((d) => d.x)
               .y((d) => d.y),
         );

      graph
         .selectAll(".node")
         .data(myTree.descendants())
         .enter()
         .append("g")
         .attr("id", "decisiontree-nodes")
         .on("mouseover", handleMouseOver)
         .on("mouseout", handleMouseOut)
         .on("click", handleEvent)
         .attr("class", "node")
         .attr("transform", (node) => `translate(${node.x},${node.y})`)
         .append("circle")
         .attr("r", 15)
         .attr("fill", DecisionTreeKPI);
   }, [currentData, d3Chart, handleMouseOver, handleMouseOut, handleEvent, DecisionTreeKPI]);

   useEffect(() => {
      d3.select("body")
         .append("div")
         .attr("class", "d3-tooltip")
         .style("position", "absolute")
         .style("background-color", "black")
         .style("color", "white")
         .style("padding", "5px")
         .style("border-radius", "5px")
         .style("opacity", 0);

      desenharDecisionTree();

      return () => d3.select(".d3-tooltip").remove();
   }, [currentData, desenharDecisionTree]);

   return <svg ref={d3Chart} id="decisiond3-svg" />;
};
