import React, { useState } from 'react';
import SinglePrediction from './SinglePrediction';
import BatchPrediction from './batchPrediction/BatchPrediction';
import { useTranslation } from "react-i18next";
import "./../../../assets/css/views/popups/predictions/prediction.css"
function Prediction({ targetName, fields, modelId, algorithmType }) {
    const { t } = useTranslation();
    const [predictionSingle, setPredictionSingle] = useState(true);

    const canMakeBatchPrediction = () => {
        const name = algorithmType.toLowerCase();
        return !(name === "svm" || name === "multilayerperceptron");
    };

    return (
        <div className="prediction-popup">
            <header>
                <div className="title">
                    <p>{t('popup.predictions.title')}</p>
                </div>
                <ul>
                    <li>
                        <button onClick={() => setPredictionSingle(true)}>
                            <p style={predictionSingle ? { color: "#22577A", fontWeight: 600 } : {}}>
                                {t('popup.predictions.button.single')}
                            </p>
                        </button>
                    </li>
                    {canMakeBatchPrediction() && (
                        <li>
                            <button onClick={() => setPredictionSingle(false)}>
                                <p style={!predictionSingle ? { color: "#22577A", fontWeight: 600 } : {}}>
                                    {t('popup.predictions.button.batch')}
                                </p>
                            </button>
                        </li>
                    )}
                </ul>
            </header>
            {predictionSingle ? (
                <SinglePrediction modelId={modelId} fields={fields} targetName={targetName} />
            ) : (
                <BatchPrediction modelId={modelId} />
            )}
        </div>
    );
}

export default Prediction;
