import http from "../http-common";
import JSZip from "jszip";

/**
 * return the prediction of the model.
 *
 * @param {string} model_id - The ID of the model.
 * @returns {string} All of fields values of the model.
 */
function makesingleprediction(model_id, fields_values) {
    if (typeof fields_values === "object") {
        fields_values = JSON.stringify(fields_values);
    }
    return http.post("/makeprediction", {
        token: localStorage.getItem("user_token"),
        modelid: model_id,
        fieldsandvalues: fields_values,
    }).then((response) => {
        return response?.data;
    });
}

/**
 * return the prediction in batch of the model.
 *
 * @param {string} modelid - The ID of the model.
 * @param {string} datasourceid - The ID of the datasource.
 * @returns {string} All of fields values of the model.
 */
function makebatchprediction(modelid, datasourceid) {
    let formData = new FormData()
    formData.append("token", localStorage.getItem("user_token"))
    formData.append("modelid", modelid)
    formData.append('datasourceid', datasourceid)

    return http.post("/makebatchprediction", formData).then((response) => {
        return response?.data
    });
};

function getprediction(task_id) {
    const token = localStorage.getItem("user_token");
    return http.get(`/getprediction/${token}/${task_id}`)
        .then(response => {
            return response?.data;
        })
}

function getpredictionlistbymodelid(model_id) {
    const token = localStorage.getItem("user_token");
    return http.get(`/getpredictionlistbymodelid/${token}/${model_id}`)
        .then(response => {
            return response?.data;
        })
}

function predictiondownload(predictionid) {
    const token = localStorage.getItem("user_token");
    return http.post(`downloadprediction/`, {
        id: predictionid,
        token: token
    }, {
        responseType: 'blob'  // Indica que o retorno será um Blob
    })
        .then((response) => {
            return response.data
        })
}

function deleteprediction(id) {
    const token = localStorage.getItem("user_token");
    return http.delete(`/deleteprediction/${id}/${token}`).then(response => {
        return response?.data;
    })
}

function deleteallmodelprediction(modelId) {
    const token = localStorage.getItem("user_token");
    return http.delete(`/deleteallmodelprediction/${modelId}/${token}`).then(response => {
        return response?.data;
    })
}

const predictionService = {
    makesingleprediction,
    makebatchprediction,
    getprediction,
    getpredictionlistbymodelid,
    predictiondownload,
    deleteprediction,
    deleteallmodelprediction
};

export default predictionService;