import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../../components/Card";
import WandIcon from "../../../../assets/icons/jsxIcons/WandIcon";
import predictionService from "../../../../services/PredictionService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRunningTask } from "../../../../store/slices/tasks/tasksSlice";
import handleErrorResponse from "../../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "semantic-ui-react";

const RunBatchPrediction = ({ datasources, modelId, setTaskId, isLoading, setIsLoading }) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const history = useHistory();
   const [selectedDatasourceId, setSelectedDatasourceId] = useState(null);

   const handleCardClick = (datasourceId) => {
      setSelectedDatasourceId(datasourceId); // Atualiza o datasource selecionado
   };

   const makeBatchPrediction = () => {
      if (isLoading || !selectedDatasourceId) return;

      setIsLoading(true);
      predictionService
         .makebatchprediction(modelId, selectedDatasourceId)
         .then((data) => {
            if (data) {
               toast.info(t("popup.predictions.info.makebatchprediction"));
               dispatch(setRunningTask(true));
               setTaskId(data);
            }
         })
         .catch((error) => {
            setIsLoading(false);
            handleErrorResponse(history, error, t);
         });
   };

   return (
      <>
         <p className="batchprediction-info">{t("popup.predictions.info.batchprediction")}</p>
         <div className="batch-prediction">
            <div className="list-cards">
               {datasources.map((datasource) => (
                  <Card
                     key={datasource.id}
                     type="datasource"
                     name={datasource.name}
                     description={datasource.name}
                     date={datasource.date}
                     elementId={datasource.id}
                     isShared={false}
                     onClick={() => handleCardClick(datasource.id)}
                     selectMode={true} // Define se o Card está em modo de seleção
                     selected={selectedDatasourceId === datasource.id} // Define se o Card está selecionado
                  />
               ))}
            </div>
         </div>
         <button
            onClick={makeBatchPrediction}
            disabled={!selectedDatasourceId || isLoading}
            className={`button-prediction ${!selectedDatasourceId ? "disabled" : ""}`}
         >
            {isLoading ? <Loader active inline size="tiny" /> : <WandIcon />}
         </button>
      </>
   );
};

export default RunBatchPrediction;
