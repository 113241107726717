import React from "react";
import { useSelector } from "react-redux";
import { selectSize } from "../store/slices/user/userSlice";
import { LinearProgress, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DiskUsageInfo = () => {
   const diskUsage = (useSelector(selectSize) / (1024 * 1024)).toFixed(2); // em MB
   const maxDisk = 100; // 100 MB
   const percentageUsed = (diskUsage / maxDisk) * 100;
   const { t } = useTranslation();

   return (
      <Box width="100%" margin="0 auto" padding="16px" borderRadius="8px">
         <Typography variant="body1" gutterBottom align="left">
            {t("sidebar.disk_usage.usage")}: {diskUsage} MB {t("sidebar.disk_usage.of")} {maxDisk} MB
         </Typography>
         <LinearProgress variant="determinate" value={percentageUsed} style={{ height: "10px", borderRadius: "5px" }} />
         <Typography variant="body2" color="textSecondary" align="right">
            {percentageUsed.toFixed(1)}% {t("sidebar.disk_usage.used")}
         </Typography>
      </Box>
   );
};

export default DiskUsageInfo;
