import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import "../assets/css/components/inputslider.css";
import { TextField } from "@material-ui/core";

function InputSlider({
   currentValue,
   setCurrentValue,
   isPercentage,
   min,
   max,
   id,
   step,
   handleValue,
   disabled,
}) {
   const handleChange = (event, newValue) => {
      if (handleValue !== undefined) {
         handleValue(newValue);
      } else {
         setCurrentValue(newValue);
      }
   };

   const handleInputChange = (event) => {
      const inputValue = event.target.value;

      // Permitir somente valores numéricos ou vazio para permitir apagar temporariamente
      const numericValue = inputValue === '' ? '' : Number(inputValue);

      if (numericValue === '' || (numericValue >= min && numericValue <= max)) {
         setCurrentValue(numericValue);
      }
   };

   const handleBlur = () => {
      // Garantir que ao perder o foco, o valor esteja dentro do range permitido
      if (currentValue === '' || currentValue < min) {
         setCurrentValue(min);
      } else if (currentValue > max) {
         setCurrentValue(max);
      }
   };

   const useStyles = makeStyles({
      root: {
         color: "#38A3A5",
      },
      thumb: {
         border: "1px solid currentColor",
         "&:focus, &:hover, &$active": {
            boxShadow: "#ccc 0 0px 0px 0px",
         },
      },
      active: {},
      inputNoArrows: {
         '& input[type=number]': {
            '-moz-appearance': 'textfield',
            '&::-webkit-outer-spin-button': {
               '-webkit-appearance': 'none',
               margin: 0,
            },
            '&::-webkit-inner-spin-button': {
               '-webkit-appearance': 'none',
               margin: 0,
            },
         },
      },
   });

   const classes = useStyles();

   return (
      <div className="containerInputSlider">
         <div className="slider">
            <Slider
               value={typeof currentValue === "number" ? currentValue : min}
               min={min}
               max={max}
               step={typeof step === "undefined" ? 1 : step}
               onChange={handleChange}
               disabled={disabled}
               valueLabelDisplay="off"
               id={id}
               classes={{ root: classes.root, thumb: classes.thumb }}
            />
         </div>
         <div className="input">
            <TextField
               value={currentValue}
               onChange={handleInputChange}
               onBlur={handleBlur}
               disabled={disabled}
               className={classes.inputNoArrows}
               inputProps={{
                  step: step || 1,
                  min: min,
                  max: max,
                  type: "number", // Volta para o tipo number para respeitar limites de min e max no teclado
                  "aria-labelledby": id,
               }}
            />
         </div>
         {isPercentage && <span>%</span>}
      </div>
   );
}

export default InputSlider;
