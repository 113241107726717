import React, { useCallback, useEffect, useState } from "react";
import styles from "../../assets/css/views/screens/dataset.module.css";
import { useTranslation } from "react-i18next";
import "../../translation/i18n";
import { DadosIcon } from "../../assets/icons/jsxIcons/DadosIcon";
import DecisionTreeIcon from "../../assets/icons/jsxIcons/DecisionTreeIcon";
import DatasetData from "../../components/Dataset/DatasetData";
import DatasetModels from "../../components/Dataset/DatasetModels";
import { useDispatch, useSelector } from "react-redux";
import {
   selectDataSetsContent,
   selectNeedGetAllData,
   selectNeedUpdateDataset,
   setDataSetsContent,
   setNeedGetAllData,
   setNeedUpdateDataset,
} from "../../store/slices/cache/cacheSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import datasetService from "../../services/DatasetService";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import DatasetTour from "../../components/Wizard/DatasetTour";
import { selectTutorialDone } from "../../store/slices/wizard/wizardSlice";

const useFetchData = (pageId) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const { t } = useTranslation();

   const cachedDataSet = useSelector(selectDataSetsContent).find((dataSource) => dataSource.id === pageId);
   const needUpdateDataSource = useSelector(selectNeedUpdateDataset);

   const needGetAllData = useSelector(selectNeedGetAllData);

   const [datasetName, setDatasetName] = useState("");
   const [projectName, setProjectName] = useState("");

   const fetchData = useCallback(async () => {
      try {
         let data;
         if (needGetAllData && cachedDataSet.datasetData.length !== cachedDataSet.numberOfItems) {
            dispatch(setNeedGetAllData(false));
            let datasetData = await datasetService.getAllDataset(pageId);
            dispatch(setDataSetsContent({ ...cachedDataSet, datasetData: datasetData }));
         } else if (!cachedDataSet || needUpdateDataSource) {
            data = await datasetService.getdataproxy(pageId);
            const clonedDataSet = { ...data, id: pageId };
            dispatch(setDataSetsContent(clonedDataSet));
         } else {
            data = cachedDataSet;
         }
         setDatasetName(data?.name);
         setProjectName(data?.projectName);
      } catch (error) {
         handleErrorResponse(history, error, t);
      } finally {
         dispatch(setNeedUpdateDataset(false));
      }
   }, [cachedDataSet, needUpdateDataSource, pageId, dispatch, history, t, needGetAllData]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   return { datasetName, projectName };
};

export const Dataset = ({ match }) => {
   const {
      params: { pageId },
   } = match;

   const { t } = useTranslation();

   const [isDatasetTab, setIsDatasetTab] = useState(true);

   const [activeTab, setActiveTab] = useState("dados");

   const { datasetName, projectName } = useFetchData(pageId);

   const tutorialDone = useSelector(selectTutorialDone);

   return (
      <div className={styles.dataset}>
         <header className={styles.header}>
            <div className={styles.infos}>
               <span>
                  {t("dataset.header.text_2")} {projectName}
               </span>
               <span className={styles.datasetName}>Dataset {datasetName}</span>
            </div>
            <div className={styles.headerButtons}>
               <button className={isDatasetTab && styles.activeButton} onClick={() => setIsDatasetTab(true)}>
                  <DadosIcon />
                  {t("dataset.header.option_1")}
               </button>
               <button className={!isDatasetTab && styles.activeButton} onClick={() => setIsDatasetTab(false)}>
                  <DecisionTreeIcon />
                  {t("dataset.header.option_2")}
               </button>
            </div>
         </header>
         {isDatasetTab ? <DatasetData activeTab={activeTab} setActiveTab={setActiveTab} /> : <DatasetModels />}
         {!tutorialDone && <DatasetTour setActiveTab={setActiveTab} setIsDatasetTab={setIsDatasetTab} />}
      </div>
   );
};
