import { toast } from "react-toastify";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import modelService from "../../../services/ModelService";
import { setModelsResult } from "../../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";

export function deleteSharing(setIsLoading, pageId, history, t) {
   setIsLoading({ delete: true });
   modelService
      .deletemodel([pageId])
      .then(() => {
         history.push("/myprojects");
      })
      .catch((error) => {
         handleErrorResponse(history, error, t);
      })
      .finally(() => setIsLoading({ delete: false }));
}

//função que deleta o modelo
export function deleteModel(setIsLoading, pageId, history, t, setDeletePopup, dispatch) {
   if (pageId !== "create") {
      setIsLoading({ delete: true });
      modelService
         .deletemodel([pageId])
         .then(() => {
            setDeletePopup(false);
            toast.success(t("popup.action.delete.success"));
            history.push("/myprojects");
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            dispatch(setNeedUpdateProjectList(true));
            setIsLoading({ delete: false });
         });
   }
}

export function getModel(id, setIsCreated, setModel, setDataSetExists, projectList, dispatch, history, t) {
   modelService
      .getmodel(id)
      .then((data) => {
         setIsCreated(true);
         // Esses dois tipos não serão armazenados no cache. Bugou com o D3.js
         if (data.type !== "kmeans" && data.type !== "fpgrowth") {
            dispatch(setModelsResult(data));
         }
         setModel(data);
         // Verificando se o dataset original ainda existe
         setDataSetExists(
            projectList.some((project) => project.datasets.some((dataSet) => dataSet.id === data.datasetId)),
         );
      })
      .catch((error) => {
         handleErrorResponse(history, error, t);
      });
}


export function getTypeAndSetDisplayName(props, setModelDisplayName, setAttributesALS) {
   let type = "";
   switch (props?.nameAlgorithm) {
      case "Decision Tree":
         type = "decisiontree";
         setModelDisplayName("árvore de decisão");
         break;
      case "Gradient Boosting":
         type = "gradientboosting";
         setModelDisplayName("Gradient Boosting");
         break;
      case "Random Forest":
         type = "randomforest";
         setModelDisplayName("floresta aleatória");
         break;
      case "Linear Regression":
         type = "linearregression";
         setModelDisplayName("regressão linear");
         break;
      case "Support Vector Machine":
         type = "supportvectormachine";
         setModelDisplayName("support vector machine");
         break;
      case "Cluster":
         type = "kmeans";
         setModelDisplayName("cluster");
         break;
      case "Isolation Forest":
         type = "isolationforest";
         setModelDisplayName("detecção de anomalias");
         break;
      case "FPGrowth":
         type = "fpgrowth";
         setModelDisplayName("descoberta de associações");
         break;
      case "Multilayer Perceptron":
         type = "multilayerperceptron";
         setModelDisplayName("perceptron multicamadas");
         break;
      case "ALS":
         type = "filtrocolaborativo";
         setModelDisplayName("filtro colaborativo");
         setAttributesALS(props?.attributesALS);
         break;
      case "Yolo":
         type = "objectdetection";
         setModelDisplayName("Yolo");
         break;
      default:
         setModelDisplayName("");
         break
   }
   return type;
}