import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/screens/model.css";

import TrashIcon from "../../../assets/icons/jsxIcons/TrashIcon";
import CheckIcon from "../../../assets/icons/jsxIcons/CheckIcon";
import DownloadIcon from "../../../assets/icons/jsxIcons/DownloadIcon";
import ShareIcon from "../../../assets/icons/jsxIcons/ShareIcon";
import KeyIcon from "../../../assets/icons/jsxIcons/KeyIcon";

import { Popup } from "../../../components/Popup";
import modelService from "../../../services/ModelService";

import { Kmeans } from "../../models/Kmeans";
import { RandomForest } from "../../models/RandomForest";
import { DecisionTree } from "../../models/DecisionTree";
import { LinearRegression } from "../../models/LinearRegression";
import { CollaborativeFiltering } from "../../models/CollaborativeFiltering";

import { DownloadModelPopup } from "../../popups/actions/DownloadModelPopup";
import GeneratedPredictionsPopup from "../../popups/predictions/GeneratedPredictionsPopup";
import ShareModelPopup from "../../popups/actions/ShareModelPopup/ShareModelPopup";
import ConfirmationPopup from "../../popups/actions/ConfirmationPopup";
import ApiPopups from "../../../components/ApiPopups";
import { useDispatch, useSelector } from "react-redux";
import { XGBoost } from "../../models/XGBoost";
import SupportVectorMachine from "../../models/SupportVectorMachine";
import FPGrowth from "../../models/FPGrowth/FPGrowth";
import IsolationForest from "../../models/IsolationForest/IsolationForest";
import {
   selectModelsResult,
   selectNeedUpdateModelResult,
   setNeedUpdateModelResult,
} from "../../../store/slices/cache/cacheSlice";
import { selectProjectList, setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import MultilayerPercetron from "../../models/MultilayerPerceptron";
import { selectMainAttribute } from "../../../store/slices/mainAttribute/mainAttributeSlice";

import { useTranslation } from "react-i18next";
import "../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import CreateModelTour from "../../../components/Wizard/CreateModelTour";
import { selectTutorialDone } from "../../../store/slices/wizard/wizardSlice";
import { toast } from "react-toastify";
import { deleteModel, deleteSharing, getModel, getTypeAndSetDisplayName } from "./utilsModel";

export const Model = ({ match, location }) => {
   const {
      params: { pageId },
   } = match;

   const { t } = useTranslation();

   let props = location.state;
   const token = localStorage.getItem("user_token");

   /** Flag que detemrina se a página terminou de carregar */
   const [isLoading, setIsLoading] = useState({
      page: false,
      savingModel: false,
      delete: false,
   });
   const mainAttribute = useSelector(selectMainAttribute);
   /** Flag que determina se o modelo está criado */
   const [isCreated, setIsCreated] = useState(false);
   /** Nome de exibição do modelo */
   const [modelDisplayName, setModelDisplayName] = useState("");
   /** Pop-up de download */
   const [downloadPopup, setDownloadPopup] = useState(false);
   /** Abrir opção para exportar modelo*/
   const [exportModel, setExportModel] = useState(false);
   /**Abrir opção para exportar modelo pela chave de api */
   const [apiKeyPopup, setApiKeyPopup] = useState(false);
   /** Pop-up de predicoẽs gerados */
   const [generatedPredictionsPopup, setGeneratedPredictionsPopup] = useState(false);
   /** Pop-up de deletar modelo */
   const [deletePopup, setDeletePopup] = useState(false);
   /** Pop-up para compartilhar o modelo */
   const [shareModelPopup, setShareModelPopup] = useState(false);
   /** Pop-up para remover o compartilhamento */
   const [deleteSharingPopup, setDeleteSharingPopup] = useState(false);

   const [attributesALS, setAttributesALS] = useState({});
   // Flag para saber se o dataset ainda existe
   const [dataSetExists, setDataSetExists] = useState(false);

   const email = useSelector((state) => state.user.email);

   const history = useHistory();

   const cachedModelResult = useSelector(selectModelsResult).find((model) => model.id === pageId);

   const needUpdateModelResult = useSelector(selectNeedUpdateModelResult);

   const projectList = useSelector(selectProjectList);


   const tutorialDone = useSelector(selectTutorialDone);

   const dispatch = useDispatch();

   const [model, setModel] = useState({
      id: "",
      name: "",
      saved: false,
      datasetName: "",
      projectName: "",
      sharedWith: null,
      owner: null,
      type: "",
      datasetId: "",
      result: null,
      metrics: "",
      target: "",
      fields: null,
      framework: null,
      significantFields: null,
      hasPrediction: false,
   });

   const handleGetModel = () => {
      getModel(pageId, setIsCreated, setModel, setDataSetExists, projectList, dispatch, history, t);
   };

   const handleDeleteModel = () => {
      deleteModel(setIsLoading, pageId, history, t, setDeletePopup, dispatch);
   };

   const handleDeleteSharing = () => {
      deleteSharing(setIsLoading, pageId, history, t);
   };

   useEffect(() => {
      if (pageId === "create") {
         setIsCreated(false);
         setDataSetExists(true);
         const type = getTypeAndSetDisplayName(props, setModelDisplayName, setAttributesALS);
         let auxListNameData = [];
         props?.tableDataActive.forEach((data) => {
            auxListNameData.push(data.name);
         });
         setModel({
            ...model,
            name: props?.nameModel,
            datasetName: props?.datasetName,
            datasetId: props?.datasetId,
            projectName: props?.projectName,
            type: type,
            target: mainAttribute,
            fields: {
               usedFields: auxListNameData,
            },
            saved: false,
         });
      } else if (!cachedModelResult || needUpdateModelResult) {
         handleGetModel();
         dispatch(setNeedUpdateModelResult(false));
      } else {
         setModel(cachedModelResult);
         setDataSetExists(
            projectList.some((project) =>
               project.datasets.some((dataSet) => dataSet.id === cachedModelResult.datasetId),
            ),
         );
      }
      // eslint-disable-next-line
   }, [pageId, needUpdateModelResult, projectList]);

   const renderModel = () => {
      switch (model?.type) {
         case "decisiontree":
            return (
               <DecisionTree
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  optimizeAlg={props ? props?.optimizeAlgorithm : false}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
               />
            );
         case "gradientboosting":
            return (
               <XGBoost
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  optimizeAlg={props ? props?.optimizeAlgorithm : false}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
               />
            );
         case "kmeans":
            return (
               <Kmeans
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  optimizeAlg={props ? props?.optimizeAlgorithm : false}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDatasetExists={setDataSetExists}
               />
            );
         case "randomforest":
            return (
               <RandomForest
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  isShared={model?.sharedWith}
                  optimizeAlg={props ? props?.optimizeAlgorithm : false}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
               />
            );
         case "linearregression":
            return (
               <LinearRegression
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  optimizeAlg={props ? props?.optimizeAlgorithm : false}
               />
            );
         case "supportvectormachine":
         case "svm":
            return (
               <SupportVectorMachine
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  setDataSetExists={setDataSetExists}
               />
            );
         case "filtrocolaborativo":
            return (
               <CollaborativeFiltering
                  pageId={pageId}
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  dataSetExists={dataSetExists}
                  attributesALS={attributesALS}
                  setDatasetExists={setDataSetExists}
               />
            );
         case "isolationforest":
            return (
               <IsolationForest
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
               />
            );
         case "fpgrowth":
            return (
               <FPGrowth
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
               />
            );
         case "multilayerperceptron":
            return (
               <MultilayerPercetron
                  model={model}
                  setModel={setModel}
                  setIsCreated={setIsCreated}
                  isCreated={isCreated}
                  setGeneratedPredictionsPopup={setGeneratedPredictionsPopup}
                  dataSetExists={dataSetExists}
                  setDataSetExists={setDataSetExists}
               />
            );
         default:
            return null;
      }
   };

   return (
      <div className="model">
         <div className="scroll">
            <header className="headerModel">
               {!isCreated ? (
                  <div id="title">
                     <p>
                        {model?.projectName} | {t("model.header.create_1")} <span>{modelDisplayName}</span>
                     </p>
                  </div>
               ) : (
                  <div id="title">
                     {model?.owner === email ? (
                        <p>
                           {model?.projectName} | {model?.name}
                        </p>
                     ) : (
                        <p>
                           {model?.projectName} | {model?.name + " "} | {t("model.header.create_2") + " "}{" "}
                           <span>{model?.owner}</span>
                        </p>
                     )}
                  </div>
               )}
               <div id="top-buttons">
                  <button
                     disabled={pageId === "create" || !isCreated}
                     className={pageId === "create" || !isCreated ? "disabled" : "enabled"}
                     onClick={() => {
                        if (pageId !== "create") {
                           model?.owner === email ? setDeletePopup(true) : setDeleteSharingPopup(true);
                        }
                     }}
                  >
                     <TrashIcon />
                     <p>
                        {model?.owner === email || !isCreated
                           ? t("model.header.button.delete_1")
                           : t("model.header.button.delete_2")}
                     </p>
                  </button>

                  {model?.owner === email && model?.saved && (
                     <button
                        disabled={pageId === "create" || !isCreated}
                        className={pageId === "create" || !isCreated ? "disabled" : "enabled"}
                        onClick={() => {
                           if (pageId !== "create") setShareModelPopup(true);
                        }}
                     >
                        <ShareIcon />
                        <p id="button-model-share">{t("model.header.button.shared")}</p>
                     </button>
                  )}
                  <div className="export-buttons">
                     <button
                        disabled={pageId === "create" || !isCreated}
                        className={pageId === "create" || !isCreated ? "disabled" : "enabled"}
                        onClick={() => {
                           if (pageId !== "create") setExportModel(!exportModel);
                        }}
                     >
                        <DownloadIcon />
                        <p id="button-model-download">{t("model.header.button.export")}</p>
                     </button>
                     {exportModel && (
                        <div className="export-model">
                           <button
                              onClick={() => {
                                 if (pageId !== "create") {
                                    setDownloadPopup(true);
                                 }
                              }}
                           >
                              <p>
                                 {model.framework === "spark"
                                    ? t("model.header.button.export.type_1")
                                    : t("model.header.button.export.type_2")}
                              </p>
                           </button>
                           {model?.owner === email && (
                              <button onClick={() => setApiKeyPopup(!apiKeyPopup)}>
                                 <KeyIcon />
                                 <p>{t("model.header.button.export.type_3")}</p>
                              </button>
                           )}
                        </div>
                     )}
                  </div>
                  {/*Só aparece quando max-width <= 500px*/}
                  <button
                     id="button-mobile"
                     disabled={pageId === "create" || !isCreated}
                     className={pageId === "create" || !isCreated ? "disabled" : "enabled"}
                     onClick={() => {
                        if (pageId !== "create") {
                           setDownloadPopup(true);
                        }
                     }}
                  >
                     <DownloadIcon />
                     <p>
                        {model.framework === "spark"
                           ? t("model.header.button.export.type_1")
                           : t("model.header.button.export.type_2")}
                     </p>
                  </button>
                  {/*Só aparece quando max-width <= 500px*/}
                  <button
                     id="button-mobile"
                     disabled={pageId === "create" || !isCreated}
                     className={pageId === "create" || !isCreated ? "disabled" : "enabled"}
                     onClick={() => setApiKeyPopup(!apiKeyPopup)}
                  >
                     <KeyIcon />
                     <p>{t("model.header.button.export.type_3")}</p>
                  </button>

                  {!model?.saved && (
                     <button
                        disabled={pageId === "create" || !isCreated || isLoading.savingModel}
                        className={
                           pageId === "create" || !isCreated || isLoading.savingModel ? "disabled-fill" : "fill"
                        }
                        onClick={() => {
                           setIsLoading({ savingModel: true });
                           modelService
                              .savemodel(pageId)
                              .then(() => {
                                 toast.success(t("model.header.save.name_2"));
                                 setModel({ ...model, saved: true });
                              })
                              .catch((error) => {
                                 handleErrorResponse(history, error, t);
                              })
                              .finally(() => {
                                 setIsLoading({ savingModel: false });
                                 dispatch(setNeedUpdateModelResult(true));
                              });
                        }}
                     >
                        <CheckIcon style={{ color: "#FDFDFD" }} />
                        <p>{t("model.header.button.save")}</p>
                     </button>
                  )}
               </div>
            </header>
            {renderModel()}

            <Popup trigger={downloadPopup} setTrigger={setDownloadPopup} enableCloseIcon={false}>
               <DownloadModelPopup setTrigger={setDownloadPopup} modelResultId={model.id} modelName={model?.name} />
            </Popup>

            <Popup trigger={generatedPredictionsPopup} setTrigger={setGeneratedPredictionsPopup}>
               <GeneratedPredictionsPopup
                  modelResultId={model?.id}
                  modelName={model?.name}
                  projectName={model?.projectName}
               />
            </Popup>

            <Popup trigger={deletePopup} setTrigger={setDeletePopup} enableCloseIcon={false}>
               <ConfirmationPopup
                  title={t("model.confirm.deletemodel")}
                  description={""}
                  confirmText={t("model.confirm.yes")}
                  denyText={t("model.confirm.no")}
                  onConfirm={handleDeleteModel}
                  isLoading={isLoading.delete}
                  onDeny={() => setDeletePopup(false)}
               />
            </Popup>

            <Popup trigger={shareModelPopup} setTrigger={setShareModelPopup} enableCloseIcon={false}>
               <ShareModelPopup modelId={pageId} />
            </Popup>

            <Popup trigger={deleteSharingPopup} setTrigger={setDeleteSharingPopup} enableCloseIcon={false}>
               <ConfirmationPopup
                  title={t("model.confirm.deleteshare")}
                  description={""}
                  confirmText={t("model.confirm.yes")}
                  denyText={t("model.confirm.no")}
                  onConfirm={() => {
                     handleDeleteSharing();
                     dispatch(setNeedUpdateProjectList(true));
                     setDeleteSharingPopup(false);
                  }}
                  isLoading={isLoading.delete}
                  onDeny={() => setDeleteSharingPopup(false)}
               />
            </Popup>
            <ApiPopups token={token} trigger={apiKeyPopup} modelID={model.id} setTrigger={setApiKeyPopup} />
         </div>
         {!tutorialDone && <CreateModelTour modelIsCreated={isCreated} />}
      </div>
   );
};
