import React, { useState, useEffect } from "react";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import WandIcon from "../../assets/icons/jsxIcons/WandIcon";
import RandomForestIcon from "../../assets/icons/jsxIcons/RandomForestIcon";
import ArrowDownFilledIcon from "../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../assets/icons/jsxIcons/ArrowRightFilledIcon";
import { Popup } from "../../components/Popup";
import InputSlider from "../../components/Slider";
import LoadingWheel from "../../components/LoadingWheel";
import { Checkbox } from "../../components/Checkbox";
import Dropdown from "../../components/Dropdown";
import Prediction from "../popups/predictions/Prediction";

import { RandomForestD3 } from "../../graphicsComponents/modelsRepresentations/RandomForestD3";

import ws from "../../services/wiselib";
import modelService from "../../services/ModelService";

import dp from "../../helpers/data_parse";

import { MetricsPopup } from "../popups/informations/MetricsPopup";
import { TreeImportancePopup } from "../popups/informations/TreeImportancePopup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectUrl } from "../../store/slices/url/urlSlice";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import TargetModel from "../../components/TargetModel";
import SaveStatus from "../../components/SaveStatus";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import { selectMainAttribute } from "../../store/slices/mainAttribute/mainAttributeSlice";
import ModeTabs from "../../components/ModeTabs";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";
import { Button } from "../../components/Button";
import { Loader } from "semantic-ui-react";
import DownloadIcon from "../../assets/icons/jsxIcons/DownloadIcon";

// Pedro : dar uma limpada nos paremetros das funções em geral, não só aqui?

export const RandomForest = ({
   pageId,
   model,
   setModel,
   setGeneratedPredictionsPopup,
   optimizeAlg,
   setIsCreated,
   isCreated,
   dataSetExists,
   setDataSetExists,
}) => {
   const mainAttribute = useSelector(selectMainAttribute);
   const [readOnly, setReadOnly] = useState(isCreated);

   const { t } = useTranslation();

   /** Percentual de treinamento do algoritmo */
   const [percentualTreinamento, setPercentualTreinamento] = useState(75);
   /** Quantidade de caminhos de cada árvore */
   const [qtdCaminhos, setQtdCaminhos] = useState(32);
   /** Quantidade de níveis de cada árvore */
   const [qtdNiveis, setQtdNiveis] = useState(5);
   /** Quantidade de árvores */
   const [numArvores, setNumArvores] = useState(4);
   /** Bool que define se a task ainda está carregando */
   const [taskIsLoading, setTaskIsLoading] = useState(false);

   const [isLoading, setIsLoading] = useState(false);
   /** Lista de árvores da floresta aleatória */
   const [treeList, setTreeList] = useState([]);
   /** Árvore selecionada atualmente */
   const [selectedTree, setSelectedTree] = useState(0);
   /** Progresso atual do modelo gerado pelo back-end */
   const [progress, setProgress] = useState(0);
   /** Caminho selecionado da árvore atual */
   const [caminhoAtual, setCaminhoAtual] = useState([]);
   /** Nó selecionado da árvore atual */
   const [selectedNode, setSelectedNode] = useState(null);
   /** Pop-up de importância dos campos */
   const [importancePopup, setImportancePopup] = useState(false);
   /** Pop-up de predições */
   const [predictionPopup, setPredictionPopup] = useState(false);
   /** Opção de otmização */
   const [optimizeAlgorithm, setOptimizeAlgorithm] = useState(optimizeAlg);
   /** Opção para floresta de regressão linear */
   const [isRegressionTree, setIsRegressionTree] = useState(false);
   /** Pop-up de metricas */
   const [metricsPopup, setMetricsPopup] = useState(false);
   /** Opção de crossValidation */
   const [crossValidation, setCrossValidation] = useState(false);

   const [selectedMLFramework, setSelectedMLFramework] = useState("spark");

   // Estado para rastrear a expansão de cada node
   const [expandStates, setExpandStates] = useState(Array(caminhoAtual.length).fill(false));

   const [showZoom, setShowZoom] = useState(false);

   const frameworkOptions = [
      { label: "Spark", value: "spark" },
      { label: "Scikit-learn", value: "sklearn" },
   ];

   const { parameters } = model;

   const [randomContent, setRandomContent] = useState([]);

   const dispatch = useDispatch();
   const history = useHistory();

   const url = useSelector(selectUrl);

   // Função para alternar o estado de expansão de um node específico
   const onHandleExpand = (index) => {
      const newExpandStates = [...expandStates];
      newExpandStates[index] = !newExpandStates[index]; // Alterna o estado do item clicado
      setExpandStates(newExpandStates);
   };

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress < 100) {
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  setModel(data);
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      setIsCreated(!!model?.id);
      setShowZoom(!!model?.id);
      let featureList = [];
      model?.result?.trees.forEach((data, index) => {
         if (data?.Feature) {
            featureList.push("Árvore #" + (index + 1));
         }
      });
      setTreeList(featureList);
      // eslint-disable-next-line
   }, [pageId]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setRandomContent(
            <RandomForestD3
               result={model?.result}
               fieldList={model?.fields?.usedFields}
               treeList={treeList}
               selectedTree={selectedTree}
               setSelectedTree={setSelectedTree}
               caminhoAtual={caminhoAtual}
               setCaminhoAtual={setCaminhoAtual}
               selectedNode={selectedNode}
               setSelectedNode={setSelectedNode}
            />,
         );
      }
      // eslint-disable-next-line
   }, [model, selectedTree]);

   // Função para renderizar os thresholds
   const renderThreshold = (node, index) => {
      if (Array.isArray(node.Threshold)) {
         return node.Threshold.map((value, idx) =>
            idx === 0 ? (
               <span id="info-array" key={`threshold-${value}`}>
                  <span id="value">{isNaN(value) ? value : dp.removeDecimals(parseFloat(value))}</span>
                  {node.Threshold.length > 1 && (
                     <span onClick={() => onHandleExpand(index)} tabIndex={0}>
                        {!expandStates[index] ? <ArrowRightFilledIcon /> : <ArrowDownFilledIcon />}
                     </span>
                  )}
               </span>
            ) : (
               expandStates[index] && (
                  <div id="info-array" key={`threshold-${value}`}>
                     <span id="value">{isNaN(value) ? value : dp.removeDecimals(parseFloat(value))}</span>
                  </div>
               )
            ),
         );
      } else {
         return isNaN(node.Threshold) ? node.Threshold : dp.removeDecimals(parseFloat(node.Threshold));
      }
   };

   const handleDownloadNode = () => {
      let caminhoArray = [];
      for (let index = 0; index < caminhoAtual.length - 1; index++) {
         let node = caminhoAtual[index];
         let next = caminhoAtual[index + 1];

         if (!next || node?.Operation[0] === "" || node?.Operation[1] === "") break;

         caminhoArray.push({
            field: node.Feature,
            operation: caminhoAtual.length > 1 ? (next.Side === "left" ? node.Operation[0] : node.Operation[1]) : "",
            value: node.Threshold,
         });
      }

      let caminhoToSend = JSON.stringify(caminhoArray);
      setIsLoading(true);
      modelService
         .downloadNodeDT(model?.id, caminhoToSend)
         .then((data) => {
            setIsLoading(false);
            const blob = new Blob([data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `subdatasetNodeDT.csv`;
            a.click();
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
            setIsLoading(false);
         });
   };

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <RandomForestIcon />
                     <p id="algorithm-name">{t("model.randomforest.name")}</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                           id="model-dataset"
                        >
                           {model?.datasetName}
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
                  <TargetModel
                     readOnly={readOnly}
                     model={model}
                     dataSetExists={dataSetExists}
                     setDataSetExists={setDataSetExists}
                     nameAlgorithm={"Random Forest"}
                  />
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.randomforest.slider_1.info")}>
                        <p id="training-split-heading">{t("model.randomforest.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0}
                        max={100}
                        defaultValue={75}
                        currentValue={readOnly ? parameters?.TrainFraction * 100 : percentualTreinamento}
                        disabled={readOnly}
                        isPercentage={true}
                        setCurrentValue={setPercentualTreinamento}
                        id="training"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.randomforest.slider_2.info")}>
                        <p id="max-bins-heading">{t("model.randomforest.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={4}
                        max={100}
                        defaultValue={32}
                        currentValue={readOnly ? parameters?.MaxBins : qtdCaminhos}
                        disabled={readOnly}
                        setCurrentValue={setQtdCaminhos}
                        id="max-bins"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.randomforest.slider_3.info")}>
                        <p id="max-depth-heading">{t("model.randomforest.slider_3")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={4}
                        max={16}
                        defaultValue={5}
                        currentValue={readOnly ? parameters?.MaxDepth : qtdNiveis}
                        disabled={readOnly}
                        setCurrentValue={setQtdNiveis}
                        id="max-depth"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.randomforest.slider_4.info")}>
                        <p id="#num-trees-heading">{t("model.randomforest.slider_4")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={1}
                        max={10}
                        defaultValue={4}
                        currentValue={readOnly ? parameters?.NumTrees : numArvores}
                        disabled={readOnly}
                        setCurrentValue={setNumArvores}
                        id="num_tree"
                     />
                  </div>

                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={optimizeAlgorithm}
                        disabled={taskIsLoading || crossValidation || readOnly}
                        onChange={() => {
                           setOptimizeAlgorithm(!optimizeAlgorithm);
                        }}
                     />
                     <p>{t("model.randomforest.checkbox_1")}</p>
                  </div>

                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || optimizeAlgorithm || readOnly}
                        onChange={() => {
                           if (optimizeAlgorithm === false) setCrossValidation(!crossValidation);
                        }}
                     />
                     <p>{t("model.randomforest.checkbox_2")}</p>
                  </div>

                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           if (isRegressionTree === false) setIsRegressionTree(!isRegressionTree);
                        }}
                     />
                     <p>{t("model.randomforest.checkbox_3")}</p>
                  </div>

                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        onChange={setSelectedMLFramework}
                        options={frameworkOptions}
                        disabled={readOnly}
                     />
                  </div>
               </div>

               {!readOnly && (
                  <div className="model-execute">
                     <button
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                        onClick={() => {
                           setRandomContent([]);
                           if (!taskIsLoading) {
                              setIsCreated(false);
                              setTaskIsLoading(true);
                              setProgress(0);
                              setModel({
                                 ...model,
                                 id: "",
                                 name: "",
                                 saved: false,
                                 sharedWith: null,
                                 result: null,
                                 metrics: "",
                                 significantFields: null,
                                 hasPrediction: false,
                              });
                              ws.randomforest(
                                 model?.name,
                                 model?.datasetId,
                                 percentualTreinamento,
                                 qtdCaminhos,
                                 qtdNiveis,
                                 numArvores + 1,
                                 mainAttribute,
                                 model?.fields?.usedFields.toString(),
                                 optimizeAlgorithm,
                                 crossValidation,
                                 isRegressionTree,
                                 selectedMLFramework,
                              )
                                 .then((id) => {
                                    dispatch(setRunningTask(true));
                                    setTaskId(id);
                                 })
                                 .catch((error) => {
                                    setTaskIsLoading(false);
                                    handleErrorResponse(history, error, t);
                                 });
                           }
                        }}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>

            <div className="model-result-1">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {randomContent}
               {/* {showZoom && (
            <div className="scroll-zoom">
              <MouseIcon /> <span>{t("model.randomforest.graphic_text")}</span>
            </div>
          )} */}
            </div>
            <div className="model-info">
               {caminhoAtual.length > 0 && (
                  <Button type="button" buttonSize="btn--auto" disabled={isLoading} onClick={handleDownloadNode}>
                     {isLoading ? (
                        <Loader inline active />
                     ) : (
                        <span id="download-icon">
                           <DownloadIcon style={{ color: "#ffffff" }} />
                        </span>
                     )}
                     <CustomTooltipWrapper title={t("model.decisiontree.download_node")}>
                        <strong>{t("popup.action.downloaddataset.download")}</strong>
                     </CustomTooltipWrapper>
                  </Button>
               )}
               {caminhoAtual.map((t, index) => {
                  let next = caminhoAtual[index + 1];
                  return (
                     <div id="info" key={index}>
                        <div id="nameField" title={t.Feature}>
                           {t.Feature}
                        </div>
                        {next?.Side !== undefined &&
                           caminhoAtual.length > 1 &&
                           (next?.Side === "left" ? (
                              <span id="operation" title={t.Operation[0]}>
                                 {t.Operation[0]}
                              </span>
                           ) : (
                              <span id="operation" title={t.Operation[0]}>
                                 {t.Operation[1]}
                              </span>
                           ))}
                        <div id="value" title={t.Threshold}>
                           {renderThreshold(t, index)}
                        </div>
                     </div>
                  );
               })}
            </div>
         </div>
         {model?.id && (
            <div className="buttons-bottom-algorithm">
               <select
                  onChange={(e) => {
                     setSelectedTree(e.target.value);
                  }}
               >
                  {treeList.map((tree, index) => {
                     return (
                        <option key={index} value={index}>
                           {tree}
                        </option>
                     );
                  })}
               </select>
               {model?.isCategorical && (
                  <button
                     onClick={() => {
                        setMetricsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               <button
                  onClick={() => {
                     setImportancePopup(true);
                  }}
               >
                  <p>{t("model.footer.button_2")}</p>
               </button>
               {model?.hasPrediction && (
                  <button
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
               <button
                  onClick={() => {
                     if (model?.id) setPredictionPopup(true);
                  }}
                  id="fill"
               >
                  <WandIcon />
                  <p>{t("model.footer.button_4")}</p>
               </button>
            </div>
         )}

         <Popup trigger={importancePopup} setTrigger={setImportancePopup}>
            <TreeImportancePopup
               setTrigger={setImportancePopup}
               modelResult={model?.result}
               modelName={model?.name}
               fieldList={model?.fields?.usedFields}
               significances={model?.significantFields}
            />
         </Popup>

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>

         <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
            <Prediction
               targetName={model?.target}
               fields={model?.fields}
               modelId={model?.id}
               algorithmType={model?.type}
            />
         </Popup>
      </div>
   );
};
