import { Dropdown, Input, Loader } from "semantic-ui-react";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/popups/actions/profileditpopup.css";

import { Button } from "../../../components/Button";

import ws from "../../../services/wiselib";

import { useDispatch, useSelector } from "react-redux";
import EmailConfirmationPopup from "./EmailConfirmationPopup";
import { selectUser, setNeedRefetchUser } from "../../../store/slices/user/userSlice";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { toast } from "react-toastify";
import { Popup } from "../../../components/Popup";

export const ProfileEditPopup = ({ setTrigger }) => {
   const { t } = useTranslation();
   const user = useSelector(selectUser);
   const [formData, setFormData] = useState({
      newUserName: "",
      newUserEmail: "",
      newUserPassword: "",
      confirmNewPassword: "",
      newUserImage: "",
      newUserAge: "",
      newUserCompany: "",
      newUserCompanyMarketField: "",
      newUserCompanyNumberOfEmployees: "",
      newUserPosition: "",
      newUserCity: "",
      newUserState: "",
      newUserCountry: "",
      newUserIntendedUse: "",
      newUserHowCameToKnow: "",
      newUserLevelOfMLExperience: "",
   });

   const [emailValidation, setEmailValidation] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const token = localStorage.getItem("user_token");
   const dispatch = useDispatch();
   const history = useHistory();

   const options = {
      numberOfEmployees: {
         values: ["Up to 9", "10 to 49", "50 to 99", "More than 100"],
         useMapping: {
            "Até 9": "Up to 9",
            "10 a 49": "10 to 49",
            "50 a 99": "50 to 99",
            "Mais de 100": "More than 100",
         },
         options: [
            t("popup.action.profileedit.inputs.companynumberofemployees.select.option_1"),
            t("popup.action.profileedit.inputs.companynumberofemployees.select.option_2"),
            t("popup.action.profileedit.inputs.companynumberofemployees.select.option_3"),
            t("popup.action.profileedit.inputs.companynumberofemployees.select.option_4"),
         ],
      },
      intendedUse: {
         values: ["Work", "Study", "Personal project", "Research", "Test", "Other"],
         useMapping: {
            Trabalho: "Work",
            Estudo: "Study",
            "Projeto pessoal": "Personal project",
            Pesquisa: "Research",
            Teste: "Test",
            Outro: "Other",
         },
         options: [
            t("popup.action.profileedit.inputs.intendeduse.select.option_1"),
            t("popup.action.profileedit.inputs.intendeduse.select.option_2"),
            t("popup.action.profileedit.inputs.intendeduse.select.option_3"),
            t("popup.action.profileedit.inputs.intendeduse.select.option_4"),
            t("popup.action.profileedit.inputs.intendeduse.select.option_5"),
            t("popup.action.profileedit.inputs.intendeduse.select.option_6"),
         ],
      },
      howCameToKnow: {
         values: ["E-mail", "Lecture", "Friends", "Other"],
         useMapping: {
            "E-mail": "E-mail",
            Palestra: "Lecture",
            Amigos: "Friends",
            Outro: "Other",
         },
         options: [
            "E-mail",
            t("popup.action.profileedit.inputs.howcametoknow.select.option_1"),
            t("popup.action.profileedit.inputs.howcametoknow.select.option_2"),
            t("popup.action.profileedit.inputs.howcametoknow.select.option_3"),
         ],
      },
      levelOfMLExperience: {
         values: ["Never had contact", "Little experience/personal projects", "Professional/advanced experience"],
         useMapping: {
            "Nunca teve contato": "Never had contact",
            "Pouca experiência/projetos pessoais": "Little experience/personal projects",
            "Experiência profissional/avançada": "Professional/advanced experience",
         },
         options: [
            t("popup.action.profileedit.inputs.experience.select.option_1"),
            t("popup.action.profileedit.inputs.experience.select.option_2"),
            t("popup.action.profileedit.inputs.experience.select.option_3"),
         ],
      },
   };

   const registerFields = [
      {
         title: t("popup.action.profileedit.inputs.name.title"),
         placeholder: `${user.firstName} ${user.lastName}`,
         type: "text",
         field: "newUserName",
      },
      {
         title: "e-mail",
         placeholder: user.email,
         type: "text",
         field: "newUserEmail",
      },
      {
         title: t("home.startpage.card.content.title.age"),
         placeholder: user.idade,
         type: "date",
         field: "newUserAge",
      },
      {
         title: t("popup.action.profileedit.inputs.company.title"),
         placeholder: user.companyName,
         type: "text",
         field: "newUserCompany",
      },
      {
         title: t("popup.action.profileedit.inputs.companymarket.title"),
         placeholder: user.companyMarketField,
         type: "text",
         field: "newUserCompanyMarketField",
      },
      {
         title: t("popup.action.profileedit.inputs.companynumberofemployees.title"),
         placeholder: t(
            `popup.action.profileedit.inputs.companynumberofemployees.select.option_${
               options.numberOfEmployees.values.indexOf(
                  options.numberOfEmployees.useMapping[user.companyNumberOfEmployees] || user.companyNumberOfEmployees,
               ) + 1
            }`,
         ),
         type: "list",
         field: "newUserCompanyNumberOfEmployees",
         values: options.numberOfEmployees.options,
      },
      {
         title: t("popup.action.profileedit.inputs.position.title"),
         placeholder: user.position,
         type: "text",
         field: "newUserPosition",
      },
      {
         title: t("popup.action.profileedit.inputs.city.title"),
         placeholder: user.city,
         type: "text",
         field: "newUserCity",
      },
      {
         title: t("popup.action.profileedit.inputs.state.title"),
         placeholder: user.state,
         type: "text",
         field: "newUserState",
      },
      {
         title: t("popup.action.profileedit.inputs.country.title"),
         placeholder: user.country,
         type: "text",
         field: "newUserCountry",
      },
      {
         title: t("popup.action.profileedit.inputs.intendeduse.title"),
         placeholder: t(
            `popup.action.profileedit.inputs.intendeduse.select.option_${
               options.intendedUse.values.indexOf(
                  options.intendedUse.useMapping[user.intendedUse] || user.intendedUse,
               ) + 1
            }`,
         ),
         type: "list",
         field: "newUserIntendedUse",
         values: options.intendedUse.options,
      },
      {
         title: t("popup.action.profileedit.inputs.howcametoknow.title"),
         placeholder: t(
            `popup.action.profileedit.inputs.howcametoknow.select.option_${
               options.howCameToKnow.values.indexOf(
                  options.howCameToKnow.useMapping[user.howCameToKnow] || user.howCameToKnow,
               ) + 1
            }`,
         ),
         type: "list",
         field: "newUserHowCameToKnow",
         values: options.howCameToKnow.options,
      },
      {
         title: t("popup.action.profileedit.inputs.experience.title"),
         placeholder: t(
            `popup.action.profileedit.inputs.experience.select.option_${
               options.levelOfMLExperience.values.indexOf(
                  options.levelOfMLExperience.useMapping[user.levelOfMLExperience] || user.levelOfMLExperience,
               ) + 1
            }`,
         ),
         type: "list",
         field: "newUserLevelOfMLExperience",
         values: options.levelOfMLExperience.options,
      },
      {
         title: t("popup.action.profileedit.inputs.password.title"),
         placeholder: "* * * * * *",
         type: "password",
         field: "newUserPassword",
      },
      {
         title: t("popup.action.profileedit.inputs.passwordagain.title"),
         placeholder: "* * * * * *",
         type: "password",
         field: "confirmNewPassword",
      },
   ];

   const handleFieldChange = useCallback((field, value) => {
      setFormData((prevData) => ({ ...prevData, [field]: value }));
   }, []);

   /**
    * Renderiza os inputs para atualizar o perfil.
    *
    * @returns {JSX.Element[]} Um array de elementos JSX representando os inputs.
    */
   const renderUpdateFieldsInputs = () =>
      registerFields.map((item) => (
         <div key={item.field} className="input-field">
            <div className="input-title">{item.title}</div>
            <div className={`${item.type}-input`}>
               {item.type === "list" ? (
                  <Dropdown
                     className="dropdown-signup"
                     fluid
                     placeholder={item.placeholder}
                     options={item.values.map((option, index) => ({
                        key: index,
                        text: option,
                        value: index,
                     }))}
                     onChange={(e, { value }) => handleFieldChange(item.field, value)}
                     selection
                  />
               ) : (
                  <Input
                     className={`${item.type}-input`}
                     placeholder={item.placeholder}
                     type={item.type}
                     onChange={(e, { value }) => handleFieldChange(item.field, value)}
                  />
               )}
            </div>
         </div>
      ));

   useEffect(() => {
      if (formData.newUserImage !== "") {
         //arrumar...
         ws.updateuserprofilepicture(formData.newUserImage, "teste.img", token).catch((error) => {
            handleErrorResponse(history, error, t);
         });
      }
      // eslint-disable-next-line
   }, [formData.newUserImage]);

   async function newUserPasswordFunction(token) {
      let newPassword = formData.newUserPassword.trim();
      let confirmPassword = formData.confirmNewPassword.trim();

      if (newPassword !== "" && confirmPassword !== "") {
         if (newPassword === confirmPassword) {
            try {
               const response = await ws.updateuserpassword(token, newPassword);
               toast.success(t(`backend.SUCCESS.${response}`));
            } catch (error) {
               handleErrorResponse(history, error, t);
            }
         } else {
            toast.error(t("popup.action.profileedit.errors.error_3"));
         }
      } else if (newPassword !== "" || confirmPassword !== "") {
         toast.error(t("popup.action.profileedit.errors.error_4"));
      }
   }

   async function newUserEmailFunction(token) {
      if (formData.newUserEmail.trim() !== "") {
         if (formData.newUserEmail.trim() !== user.email) {
            try {
               const response = await ws.updateuseremail(token, formData.newUserEmail);
               toast.success(t(`backend.SUCCESS.${response}`));
               setEmailValidation(true);
            } catch (error) {
               handleErrorResponse(history, error, t);
            }
         } else {
            toast.error(t("popup.action.profileedit.errors.error_8"));
         }
      }
   }

   async function newUserNameFunction(token) {
      let newName = formData.newUserName.trim();
      let lastName = user.firstName.trim() + " " + user.lastName.trim();

      if (newName !== "") {
         let newUserNameSplit = newName.split(" ");
         if (newUserNameSplit.length === 1) {
            toast.error(t("popup.action.profileedit.errors.error_5"));
         } else if (lastName !== newName) {
            try {
               const response = await ws.renameuser(token, newName);
               toast.success(t(`backend.SUCCESS.${response}`));
               dispatch(setNeedRefetchUser(true));
            } catch (error) {
               handleErrorResponse(history, error, t);
            }
         } else {
            toast.error(t("popup.action.profileedit.errors.error_11"));
         }
      }
   }

   async function newUserOtherInfoFunction(token) {
      const otherUserFields = {
         Age: formData.newUserAge,
         CompanyName: formData.newUserCompany.trim(),
         CompanyMarketField: formData.newUserCompanyMarketField.trim(),
         CompanyNumberOfEmployees: options.numberOfEmployees.values[formData.newUserCompanyNumberOfEmployees],
         Position: formData.newUserPosition.trim(),
         City: formData.newUserCity.trim(),
         State: formData.newUserState.trim(),
         Country: formData.newUserCountry.trim(),
         IntendedUse: options.intendedUse.values[formData.newUserIntendedUse],
         HowCameToKnow: options.howCameToKnow.values[formData.newUserHowCameToKnow],
         LevelOfMLExperience: options.levelOfMLExperience.values[formData.newUserLevelOfMLExperience],
         UserId: user.id,
      };

      let canCallEndpoint = Object.values(otherUserFields).some(
         (value) => value !== "" && value !== undefined && value !== user.id,
      );

      if (canCallEndpoint) {
         try {
            const response = await ws.updateuserinfo(token, otherUserFields);
            toast.success(t(`backend.SUCCESS.${response}`));
         } catch (error) {
            handleErrorResponse(history, error, t);
         }
      }
   }

   async function checkNewValues() {
      setIsLoading(true);

      try {
         await Promise.all([
            formData.newUserName && newUserNameFunction(token),
            formData.newUserEmail && newUserEmailFunction(token),
            newUserPasswordFunction(token),
            newUserOtherInfoFunction(token),
         ]);
      } finally {
         setIsLoading(false);
      }
   }

   const uploadProfilePicture = async (updateUserImg) => {
      const file = updateUserImg.target.files[0];
      const allowedTypes = ["image/png", "image/jpeg"];

      if (!allowedTypes.includes(file.type)) {
         toast.error(t("popup.action.profileedit.errors.error_10"));
         updateUserImg.target.value = "";
         return;
      }
      const base64 = await convertBase64(file);
      setFormData((prevData) => ({ ...prevData, newUserImage: base64 }));

      setTrigger(false);
      window.location.reload();
   };

   const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const fileReader = new FileReader();

         fileReader.readAsDataURL(file);

         fileReader.onload = () => {
            resolve(fileReader.result);
         };

         fileReader.onerror = (error) => {
            reject(new Error(error));
         };
      });
   };

   return (
      <div className="profile-edit">
         {isLoading && <Loader active />}
         <div className="description">{t("popup.action.profileedit.title")}</div>
         <div id="profile-form">{renderUpdateFieldsInputs()}</div>
         <div id="change-picture-button">
            <Button buttonStyle="btn--primary--outline" buttonSize="btn--auto">
               <label htmlFor="file-upload">{t("popup.action.profileedit.button.update")}</label>
            </Button>
            <input
               type="file"
               id="file-upload"
               name="nome"
               accept="image/png, image/jpeg"
               style={{ display: "none" }}
               onChange={(file) => {
                  uploadProfilePicture(file);
               }}
            />
         </div>
         <div id="send-button">
            <Button
               buttonStyle={isLoading ? "btn--primary--outline" : "btn--primary--solid"}
               buttonSize="btn--auto"
               onClick={() => {
                  checkNewValues();
               }}
               disabled={isLoading}
            >
               {t("popup.action.profileedit.button.send")}
            </Button>
         </div>
         <Popup trigger={emailValidation} setTrigger={setEmailValidation}>
            <EmailConfirmationPopup setTrigger={setEmailValidation} />
         </Popup>
      </div>
   );
};
