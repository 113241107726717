import React, { useState, useEffect } from "react";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import WandIcon from "../../assets/icons/jsxIcons/WandIcon";
import ArrowDownFilledIcon from "../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../assets/icons/jsxIcons/ArrowRightFilledIcon";
import { Popup } from "../../components/Popup";
import InputSlider from "../../components/Slider";
import LoadingWheel from "../../components/LoadingWheel";
import { Checkbox } from "../../components/Checkbox";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";

import { DecisionTreeD3 } from "../../graphicsComponents/modelsRepresentations/DecisionTreeD3";

import ws from "../../services/wiselib";
import modelService from "../../services/ModelService";

import dp from "../../helpers/data_parse";

import { TreeImportancePopup } from "../popups/informations/TreeImportancePopup";
import { MetricsPopup } from "../popups/informations/MetricsPopup";
import Dropdown from "../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectUrl } from "../../store/slices/url/urlSlice";
import XGBoostIcon from "../popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/XGBoostIcon";
import TargetModel from "../../components/TargetModel";
import SaveStatus from "../../components/SaveStatus";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import { setModelsResult } from "../../store/slices/cache/cacheSlice";
import { selectMainAttribute } from "../../store/slices/mainAttribute/mainAttributeSlice";
import ModeTabs from "../../components/ModeTabs";
import Prediction from "../popups/predictions/Prediction";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";

export const XGBoost = ({
   pageId,
   setGeneratedPredictionsPopup,
   optimizeAlg,
   model,
   setModel,
   setIsCreated,
   isCreated,
   dataSetExists,
   setDataSetExists,
}) => {
   const [readOnly, setReadOnly] = useState(isCreated);
   const mainAttribute = useSelector(selectMainAttribute);

   const { t } = useTranslation();

   /** Percentual de treinamento do algoritmo */
   const [percentualTreinamento, setPercentualTreinamento] = useState(75);
   /** Quantidade de caminhos da árvore */
   const [learningRate, setLearningRate] = useState(0.01);
   /** Quantidade de níveis da árvore */
   const [qtdNiveis, setQtdNiveis] = useState(5);
   /** Proporção de subamostra das instâncias de treinamento */
   const [subsample, setSubsample] = useState(0.8);
   /** Número de arvores */
   const [n_estimators, setN_Estimators] = useState(200);
   /** Bool que define se a task está carregando */
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   /** Progresso atual do resultado */
   const [progress, setProgress] = useState(0);
   /** Caminho atual selecionado na árvore */
   const [caminhoAtual, setCaminhoAtual] = useState([]);
   /** Nó atual selecinado na árvore */
   const [selectedNode, setSelectedNode] = useState(null);
   /** Pop-up de importância dos campos */
   const [importancePopup, setImportancePopup] = useState(false);
   /** Pop-up de predições */
   const [predictionPopup, setPredictionPopup] = useState(false);
   /** Opção de otmização */
   const [optimizeAlgorithm] = useState(optimizeAlg);
   // Flag se a seta de campos categoricos estão expandidas
   const [isExpand, setIsExpand] = useState(false);
   /** Opção de arvore de classificação */
   const [isRegressionTree, setIsRegressionTree] = useState(false);

   const { parameters } = model;

   /** Opção de crossValidation */
   const [crossValidation, setCrossValidation] = useState(false);

   const [selectedMLFramework, setSelectedMLFramework] = useState("xgboost");

   const frameworkOptions = [{ label: "XGBoost", value: "xgboost" }];

   const [treeContent, setTreeContent] = useState([]);

   const [metricsPopup, setMetricsPopup] = useState(false);

   const dispatch = useDispatch();
   const history = useHistory();

   const url = useSelector(selectUrl);

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress < 100) {
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setTreeContent(
            <DecisionTreeD3
               result={model?.result}
               caminhoAtual={caminhoAtual}
               setCaminhoAtual={setCaminhoAtual}
               selectedNode={selectedNode}
               setSelectedNode={setSelectedNode}
               fieldList={model?.fields?.usedFields}
            />,
         );
      }
      // eslint-disable-next-line
   }, [model]);

   const onHandleExpand = () => {
      setIsExpand(!isExpand);
   };

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <XGBoostIcon />
                     <p id="algorithm-name">Gradient Boosting</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
                  <TargetModel
                     model={model}
                     dataSetExists={dataSetExists}
                     setDataSetExists={setDataSetExists}
                     readOnly={readOnly}
                     nameAlgorithm="Gradient Boosting"
                  />
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper placement="top" title={t("model.gradientboosting.slider_1.info")}>
                        <p id="training-split-heading">{t("model.gradientboosting.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0}
                        max={100}
                        defaultValue={75}
                        currentValue={readOnly ? parameters?.TrainFraction * 100 : percentualTreinamento}
                        disabled={readOnly}
                        isPercentage={true}
                        setCurrentValue={setPercentualTreinamento}
                        id="trainings"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper placement="top" title={t("model.gradientboosting.slider_2.info")}>
                        <p id="learning-rate">{t("model.gradientboosting.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0.01}
                        max={0.1}
                        defaultValue={0.01}
                        step={0.01}
                        currentValue={readOnly ? parameters?.LearningRate : learningRate}
                        disabled={readOnly}
                        setCurrentValue={setLearningRate}
                        id="learningRate"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper placement="top" title={t("model.gradientboosting.slider_3.info")}>
                        <p id="max-depth-heading">{t("model.gradientboosting.slider_3")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={4}
                        max={16}
                        defaultValue={5}
                        currentValue={readOnly ? parameters?.MaxDepth : qtdNiveis}
                        disabled={readOnly}
                        setCurrentValue={setQtdNiveis}
                        id="qtdNiveis"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper placement="top" title={t("model.gradientboosting.slider_4.info")}>
                        <p id="subsample-heading">{t("model.gradientboosting.slider_4")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0}
                        max={1}
                        defaultValue={0.5}
                        step={0.1}
                        currentValue={readOnly ? parameters?.subSample : subsample}
                        disabled={readOnly}
                        setCurrentValue={setSubsample}
                        id="subsample"
                     />
                  </div>

                  <div className="model-slider">
                     <CustomTooltipWrapper placement="top" title={t("model.gradientboosting.slider_5.info")}>
                        <p id="n_estimators">{t("model.gradientboosting.slider_5")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={1}
                        max={1000}
                        defaultValue={200}
                        step={50}
                        currentValue={readOnly ? parameters?.Estimators : n_estimators}
                        disabled={readOnly}
                        setCurrentValue={setN_Estimators}
                        id="n_estimators"
                     />
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || optimizeAlgorithm || readOnly}
                        onChange={() => {
                           if (optimizeAlgorithm === false) setCrossValidation(!crossValidation);
                        }}
                     />
                     <p>{t("model.gradientboosting.checkbox_1")}</p>
                  </div>

                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           setIsRegressionTree(!isRegressionTree);
                        }}
                     />
                     <p>{t("model.gradientboosting.checkbox_2")}</p>
                  </div>

                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        onChange={setSelectedMLFramework}
                        options={frameworkOptions}
                        disabled={readOnly}
                     />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                        onClick={() => {
                           setTreeContent([]);
                           if (!taskIsLoading) {
                              setIsCreated(false);
                              setTaskIsLoading(true);
                              setProgress(0);
                              setModel({
                                 ...model,
                                 id: "",
                                 name: "",
                                 saved: false,
                                 sharedWith: null,
                                 result: null,
                                 metrics: "",
                                 significantFields: null,
                                 hasPrediction: false,
                              });
                              ws.xgboost(
                                 model?.name,
                                 model?.datasetId,
                                 percentualTreinamento,
                                 learningRate,
                                 qtdNiveis,
                                 mainAttribute,
                                 model?.fields?.usedFields.toString(),
                                 optimizeAlgorithm,
                                 crossValidation,
                                 selectedMLFramework,
                                 subsample,
                                 n_estimators,
                                 isRegressionTree,
                              )
                                 .then((id) => {
                                    dispatch(setRunningTask(true));
                                    setTaskId(id);
                                 })
                                 .catch((error) => {
                                    setTaskIsLoading(false);
                                    handleErrorResponse(history, error, t);
                                 });
                           }
                        }}
                     >
                        <p>{t("model.execute")}</p>
                     </button>
                  </div>
               )}
            </div>

            <div className="model-result-1">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {treeContent}
            </div>

            <div className="model-info">
               {caminhoAtual.map((node, index) => {
                  let next = caminhoAtual[index + 1];
                  return (
                     <div id="info" key={index}>
                        <span id="nameField">{node.Feature}</span>
                        {next?.Side !== undefined &&
                           caminhoAtual.length > 1 &&
                           (next?.Side === "left" ? (
                              <span id="operation">{node.Operation[0]}</span>
                           ) : (
                              <span id="operation">{node.Operation[1]}</span>
                           ))}

                        <div id="value">
                           {Array.isArray(node.Threshold)
                              ? isNaN(parseFloat(node.Threshold))
                                 ? node.Threshold.map((value, index) => {
                                      return (
                                         <div key={index} id="info-array">
                                            {value}
                                         </div>
                                      );
                                   })
                                 : node.Threshold.map((value, index) => {
                                      return index === 0 ? (
                                         <div id="info-array" key={index}>
                                            {dp.removeDecimals(parseFloat(value))}
                                            {node.Threshold.length > 1 && ( //só vai aparecer a arrow se o array tiver mais de 1 elemento
                                               <span onClick={onHandleExpand}>
                                                  {!isExpand ? <ArrowRightFilledIcon /> : <ArrowDownFilledIcon />}
                                               </span>
                                            )}
                                         </div>
                                      ) : (
                                         isExpand && <div id="info-array">{dp.removeDecimals(parseFloat(value))}</div>
                                      );
                                   })
                              : isNaN(parseFloat(node.Threshold))
                              ? node.Threshold
                              : dp.removeDecimals(parseFloat(node.Threshold))}
                        </div>
                     </div>
                  );
               })}
            </div>
         </div>
         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategotical && (
                  <button
                     onClick={() => {
                        setMetricsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               <button
                  onClick={() => {
                     setImportancePopup(true);
                  }}
               >
                  <p>{t("model.footer.button_2")}</p>
               </button>
               {model?.hasPrediction && (
                  <button
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
               <button
                  id="fill"
                  onClick={() => {
                     if (model?.id) setPredictionPopup(true);
                  }}
               >
                  <WandIcon />
                  <p>{t("model.footer.button_4")}</p>
               </button>
            </div>
         )}
         {/* Eduardo: codigo morto */}
         {/* <Popup trigger={false} setTrigger={setButtonPopup}></Popup>  */}
         <Popup trigger={importancePopup} setTrigger={setImportancePopup}>
            <TreeImportancePopup
               setTrigger={setImportancePopup}
               modelResult={model?.result}
               modelName={model?.name}
               fieldList={model?.fields?.usedFields}
               significances={model?.significantFields}
            />
         </Popup>

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup data={model?.metrics} />
         </Popup>

         <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
            <Prediction
               targetName={model?.target}
               fields={model?.fields}
               modelId={model?.id}
               algorithmType={model?.type}
            />
         </Popup>
      </div>
   );
};
