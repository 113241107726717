import { React, useState, useEffect, useCallback } from "react";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";

import "../../assets/css/views/models/kmeans.css";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import ScatterPlotIcon from "../../assets/icons/jsxIcons/ScatterPlotIcon";
import ArrowDownFilledIcon from "../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../assets/icons/jsxIcons/ArrowRightFilledIcon";

import { Popup } from "../../components/Popup";
import InputSlider from "../../components/Slider";
import { KmeansD3 } from "../../graphicsComponents/modelsRepresentations/KmeansD3";
import LoadingWheel from "../../components/LoadingWheel";
import { Checkbox } from "../../components/Checkbox";
import Dropdown from "../../components/Dropdown";

import ws from "../../services/wiselib";
import modelService from "../../services/ModelService";

import { MetricsPopup } from "../popups/informations/MetricsPopup";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import { selectUrl } from "../../store/slices/url/urlSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditableDiv from "../../components/EditableDiv";
import SaveStatus from "../../components/SaveStatus";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import ModeTabs from "../../components/ModeTabs";
import KmeansHighchart from "../../graphicsComponents/modelsRepresentations/KmeansHighchart";
import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import DownloadIcon from "../../assets/icons/jsxIcons/DownloadIcon";
import { Loader } from "semantic-ui-react";

export const Kmeans = ({
   pageId,
   model,
   setModel,
   setGeneratedPredictionsPopup,
   optimizeAlg,
   setIsCreated,
   isCreated,
   dataSetExists,
}) => {
   const [readOnly, setReadOnly] = useState(isCreated);

   const { t } = useTranslation();

   /** Número de pontos a serem exibidos no gráfico */
   const [numOfPoints, setNumOfPoints] = useState(0);
   /** Limite de pontos a serem exibidos no gráfico */
   const [pca_limit, setPca_limit] = useState(null);
   /** A posição do campo categórico escolhido */
   const [categoricalFields, setCategoricalFields] = useState("");
   /** Determina o tipo de visualização */
   const [kmeans3d, setKmeans3d] = useState(false);
   /** Quantidade de centróides do cluster */
   const [qtdCentroids, setQtdCentroids] = useState(2);
   /** Range da quantidade de centróides do cluster */
   const [qtdCentroidsArray, setQtdCentroidsArray] = useState([2, 20]);
   /** Quantidade máxima de iterações */
   const [maxIterations, setMaxIterations] = useState(100);
   /** Bool que define se a task ainda está carregando */
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   // bool que define se download do subdataset ainda está carregando
   const [isLoading, setIsLoading] = useState(false);
   /** Progresso atual do modelo que está sendo criado */
   const [progress, setProgress] = useState(0);
   /** Cluster atual selecionado */
   const [selectedCluster, setSelectedCluster] = useState(null);
   /** Opção de otmização */
   const [optimizeAlgorithm, setOptimizeAlgorithm] = useState(optimizeAlg);
   /** Pop-up de metricas */
   const [metricsPopup, setMetricsPopup] = useState(false);
   /** Opção de crossValidation */
   const [crossValidation, setCrossValidation] = useState(false);

   const [selectedMLFramework, setSelectedMLFramework] = useState("spark");

   const frameworkOptions = [
      { label: "Spark", value: "spark" },
      { label: "Scikit-learn", value: "sklearn" },
   ];

   const { parameters } = model;

   const [kmeansContent, setKmeansContent] = useState([]);

   const token = localStorage.getItem("user_token");

   const dispatch = useDispatch();
   const history = useHistory();

   const url = useSelector(selectUrl);

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress < 100) {
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  setModel(data);
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   const [resultCluster, setResultCluster] = useState([]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setKmeansContent(
            <KmeansD3
               result={model?.result}
               fieldList={model?.fields?.usedFields}
               selectedCluster={selectedCluster}
               setSelectedCluster={setSelectedCluster}
            />,
         );

         let categoricalFieldsArrays = [];
         categoricalFieldsArrays.push({ label: "Cluster", value: "Cluster" });
         for (let key in model?.fields?.categoricalFields?.encoder) {
            let fieldKey = Object.keys(model.fields.categoricalFields.encoder[key])[0];
            categoricalFieldsArrays.push({ label: fieldKey, value: fieldKey });
         }

         let collapsibleCategorical = {};
         for (let key in model?.fields?.categoricalFields) {
            if (key === "encoder") continue;
            collapsibleCategorical[key] = model?.fields?.categoricalFields[key];
         }

         collapsibleCategorical = Object.keys(collapsibleCategorical).map((name) => {
            return {
               name: name,
               column: collapsibleCategorical[name],
               selected: false,
            };
         });

         setResultCluster({
            collapsibleCategorical: collapsibleCategorical,
            categoricalFieldsArrays: categoricalFieldsArrays,
         });
      }
      // eslint-disable-next-line
   }, [model]);

   useEffect(() => {
      setIsCreated(!!model?.id);
      // eslint-disable-next-line
   }, [pageId]);

   const handleDownloadCluster = () => {
      setIsLoading(true);
      modelService
         .getClusterData(model?.id, selectedCluster.centerNumber, model?.framework)
         .then((data) => {
            setIsLoading(false);
            const blob = new Blob([data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${selectedCluster.name}.csv`;
            a.click();
         })
         .catch((error) => {
            setIsLoading(false);
            handleErrorResponse(history, error, t);
         });
   };

   // TODO: Ajustar a função, está num loop infinito em casos que não vem o pca.dataa
   const getRandomData = useCallback(
      (modelResult, maxPoints) => {
         let pca_data = modelResult.pca.data;
         setPca_limit(pca_data.length);

         if (pca_limit === null) {
            setNumOfPoints(pca_data.length);
         }
         if (pca_data.length === 0) return;
         let pca_data_reduce = [];
         let randomIndexes = [];
         while (randomIndexes.length < maxPoints) {
            let randomIndex = Math.floor(Math.random() * pca_data.length);
            if (!randomIndexes.includes(randomIndex)) {
               randomIndexes.push(randomIndex);
               pca_data_reduce.push(pca_data[randomIndex]);
            }
         }
         return pca_data_reduce;
      },
      [pca_limit],
   );

   useEffect(() => {
      if (kmeans3d) {
         KmeansHighchart.create(
            model?.result,
            getRandomData(model?.result, numOfPoints),
            model?.fields?.categoricalFields,
            categoricalFields,
         );
      } else if (model?.result) {
         try {
            KmeansHighchart.destroy();
         } catch (e) {
            toast.error(e);
         }
      }
   }, [kmeans3d, numOfPoints, categoricalFields, model?.result, model?.fields?.categoricalFields, getRandomData]);

   const changeVisualization = () => {
      if (model?.result !== undefined && !taskIsLoading && isCreated) {
         return (
            <div className="change-visualization">
               <button
                  id="left"
                  className={!kmeans3d ? "active" : ""}
                  onClick={() => {
                     setKmeans3d(false);
                  }}
               >
                  <p>{t("model.cluster.model_result.header.button_1")}</p>
               </button>
               <button
                  id="right"
                  className={kmeans3d ? "active" : ""}
                  onClick={() => {
                     setKmeans3d(true);
                  }}
               >
                  <p>{t("model.cluster.model_result.header.button_2")}</p>
               </button>
            </div>
         );
      }
   };

   const drawResult = () => {
      return (
         <>
            {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {changeVisualization()}
            </div>
            {!kmeans3d ? kmeansContent : <div id="container"></div>}
            {/* {showZoom && (
               <div className="scroll-zoom">
                  <MouseIcon />{" "}
                  <span>{t("model.cluster.graphic_text")}</span>
               </div>
            )} */}
         </>
      );
   };

   const handleRename = (modelId, index, newName) => {
      ws.renamecentroid(token, modelId, index, newName).catch((error) => {
         handleErrorResponse(history, error, t);
      });
   };

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <ScatterPlotIcon />
                     <p id="algorithm-name">K-MEANS</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.cluster.slider_1.info")}>
                        <p id="number-of-centroids-heading">{t("model.cluster.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={2}
                        max={50}
                        defaultValue={2}
                        currentValue={
                           readOnly
                              ? model?.result.centers.length
                              : optimizeAlgorithm || crossValidation
                              ? qtdCentroidsArray
                              : qtdCentroids
                        }
                        disabled={readOnly}
                        setCurrentValue={optimizeAlgorithm || crossValidation ? setQtdCentroidsArray : setQtdCentroids}
                        id="centroids"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.cluster.slider_2.info")}>
                        <p id="max-iterations-heading">{t("model.cluster.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={10}
                        max={1000}
                        defaultValue={100}
                        currentValue={readOnly ? parameters?.MaxIterations : maxIterations}
                        disabled={readOnly}
                        setCurrentValue={setMaxIterations}
                        id="iteration"
                     />
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={optimizeAlgorithm}
                        disabled={taskIsLoading || crossValidation || readOnly}
                        onChange={() => {
                           setOptimizeAlgorithm(!optimizeAlgorithm);
                        }}
                     />
                     <p>{t("model.cluster.checkbox_1")}</p>
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || optimizeAlgorithm || readOnly}
                        onChange={() => {
                           if (optimizeAlgorithm === false) setCrossValidation(!crossValidation);
                        }}
                     />
                     <p>{t("model.cluster.checkbox_2")}</p>
                  </div>
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        onChange={setSelectedMLFramework}
                        options={frameworkOptions}
                        disabled={readOnly}
                     />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                        onClick={() => {
                           setKmeansContent([]);
                           if (!taskIsLoading) {
                              setIsCreated(false);
                              setTaskIsLoading(true);
                              setProgress(0);
                              setModel({
                                 ...model,
                                 id: "",
                                 name: "",
                                 saved: false,
                                 sharedWith: null,
                                 result: null,
                                 metrics: "",
                                 significantFields: null,
                                 hasPrediction: false,
                              });
                              ws.kmeans(
                                 model?.name,
                                 model?.datasetId,
                                 qtdCentroids,
                                 maxIterations,
                                 model?.fields?.usedFields.toString(),
                                 optimizeAlgorithm,
                                 crossValidation,
                                 selectedMLFramework,
                                 qtdCentroidsArray,
                              )
                                 .then((id) => {
                                    dispatch(setRunningTask(true));
                                    setTaskId(id);
                                 })
                                 .catch((error) => {
                                    setTaskIsLoading(false);
                                    handleErrorResponse(history, error, t);
                                 });
                           }
                        }}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>

            <div className="model-result-1">{drawResult()}</div>

            <div className="model-info">
               {kmeans3d ? (
                  <>
                     <div className="info-slider">
                        <p>{t("model.cluster.3d_side_info.slider_1")}</p>
                        <InputSlider
                           min={1}
                           max={model?.result?.pca?.data.length > 1000 ? 1000 : pca_limit}
                           defaultValue={pca_limit}
                           currentValue={numOfPoints}
                           setCurrentValue={setNumOfPoints}
                        />
                        {pca_limit === 0 && (
                           <p
                              style={{
                                 backgroundColor: "#EB5757",
                                 color: "white",
                                 padding: "10px",
                                 height: "fit-content",
                                 textWrap: "wrap",
                                 borderRadius: "5px",
                              }}
                           >
                              {t("model.cluster.3d_side_info.error")}
                           </p>
                        )}
                     </div>
                     <div className="dropdown-container">
                        <p>{t("model.cluster.3d_side_info.dropdown")}</p>
                        <Dropdown
                           defaultValue={categoricalFields}
                           onChange={setCategoricalFields}
                           options={resultCluster?.categoricalFieldsArrays}
                        />
                     </div>
                     <div className="collapsible-categorical">
                        {resultCluster?.collapsibleCategorical.map((element, index) => {
                           return (
                              <div key={index} className="info-field">
                                 <button
                                    onClick={() => {
                                       let selected = (element.selected = !element.selected);
                                       let aux = resultCluster?.collapsibleCategorical.map((data) => {
                                          return {
                                             ...data,
                                             selected: false,
                                          };
                                       });
                                       aux[index].selected = selected;
                                       setResultCluster({
                                          ...resultCluster,
                                          collapsibleCategorical: aux,
                                       });
                                    }}
                                 >
                                    <p>{element.name}</p>
                                    {element.selected ? (
                                       <ArrowDownFilledIcon style={{ size: "18px", color: "#fdfdfd" }} />
                                    ) : (
                                       <ArrowRightFilledIcon style={{ size: "18px", color: "#fdfdfd" }} />
                                    )}
                                 </button>
                                 {element.selected && (
                                    <div className="column">
                                       {element.column.map((name, index2) => {
                                          return <p key={index2}>{name}</p>;
                                       })}
                                    </div>
                                 )}
                              </div>
                           );
                        })}
                     </div>
                  </>
               ) : (
                  selectedCluster !== null &&
                  model?.id && (
                     <>
                        <EditableDiv
                           name={selectedCluster.name}
                           editFunction={handleRename}
                           id={model?.id}
                           index={selectedCluster.centerNumber}
                        />
                        {!isNaN(selectedCluster?.centerNumber) && (
                           <Button
                              type="button"
                              buttonSize="btn--auto"
                              disabled={isLoading}
                              onClick={handleDownloadCluster}
                           >
                              {isLoading ? (
                                 <Loader active inline size="small" />
                              ) : (
                                 <span id="download-icon">
                                    <DownloadIcon style={{ color: "#ffffff" }} />
                                 </span>
                              )}
                              <CustomTooltipWrapper title={t("model.cluster.download_cluster")}>
                                 <strong>
                                    {t("popup.action.downloaddataset.download") +
                                       " " +
                                       selectedCluster.name.toUpperCase()}
                                 </strong>
                              </CustomTooltipWrapper>
                           </Button>
                        )}
                        {model?.fields?.usedFields.map((m, i) => {
                           return (
                              <div id="info" key={i}>
                                 <div id="nameField">{m}</div>
                                 <div id="value">
                                    {isNaN(selectedCluster?.center[i])
                                       ? selectedCluster.center[i]
                                       : parseFloat(selectedCluster.center[i]).toFixed(2)}
                                 </div>
                              </div>
                           );
                        })}
                     </>
                  )
               )}
            </div>
         </div>
         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategorical && (
                  <button
                     onClick={() => {
                        setMetricsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
            </div>
         )}

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>
      </div>
   );
};
