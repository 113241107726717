// needs:
// http-common.js file
// JSZIP (npm install jszip --save)
//
// import ws from "../wiselib";
import http from "../http-common";
import JSZIP from "jszip";

// endpoint de login
function login(email, password) {
   return http
      .post("/login", {
         email: email,
         password: password,
      })
      .then((response) => {
         return response;
      });
}

// endpoint de criação de conta
function createaccount(newUser) {
   return http
      .post("/createaccount", {
         firstName: newUser.FirstName,
         surname: newUser.Surname,
         email: newUser.Email,
         birthDate: newUser.Age,
         companyName: newUser.CompanyName,
         companyMarketField: newUser.CompanyMarketField,
         companyNumberOfEmployees: newUser.CompanyNumberOfEmployees,
         position: newUser.Position,
         city: newUser.City,
         state: newUser.State,
         country: newUser.Country,
         intendedUse: newUser.IntendedUse,
         howCameToKnow: newUser.HowCameToKnow,
         levelOfMLExperience: newUser.LevelOfMLExperience,
         password: newUser.Password,
      })
      .then((response) => {
         return response;
      });
}

// endpoint de criação de projeto
function createproject(token, name, description) {
   return http
      .post("/createproject", {
         token: token,
         object: {
            name: name,
            description: description,
         },
      })
      .then((response) => {
         return response.data;
      });
}

// endpoint que envia os dados para acessar uma collection
// já existente no mongoDB e realizar a conexão dela com o backend
function uploadmongodataset(
   token,
   prjid,
   connectionString,
   targetDatabase,
   targetCollection,
   progressEvent
) {
   return http
      .post("/importdatasetmongo", {
         token: token,
         prjid: prjid,
         connectionString: connectionString,
         targetDatabase: targetDatabase,
         targetCollection: targetCollection,
      })
      .then((response) => {
         // requisição que atualiza o progresso através de
         // getprogresstask e getprogresstoken
         let getprogress = function (gptask, gptoken) {
            const timeout = setTimeout(() => {
               http
                  .post("/getprogress", {
                     taskid: gptask,
                     token: gptoken,
                  })
                  .then((response) => {
                     var p = parseInt(response.data);
                     if (p === -1) p = 100;
                     progressEvent(p);
                     if (p < 100) getprogress(gptask, gptoken);
                  })
            }, 1000);
            return () => {
               clearTimeout(timeout);
            };
         };
         let task = response.data;
         getprogress(task, token);
         return task;
      });
}

// endpoint que envia os dados para acessar uma collection
// de banco de dados relacional

function uploadsqldataset(
   token,
   prjid,
   connectionString,
   targetTable,
   progressEvent
) {
   return http
      .post("/importdatasetsql", {
         token: token,
         prjid: prjid,
         connectionString: connectionString,
         targetTable: targetTable,
      })
      .then((response) => {
         // requisição que atualiza o progresso através de
         // getprogresstask e getprogresstoken
         let getprogress = function (gptask, gptoken) {
            const timeout = setTimeout(() => {
               http
                  .post("/getprogress", {
                     taskid: gptask,
                     token: gptoken,
                  })
                  .then((response) => {
                     var p = parseInt(response.data);
                     if (p === -1) p = 100;
                     progressEvent(p);
                     if (p < 100) getprogress(gptask, gptoken);
                  })
            }, 1000);
            return () => {
               clearTimeout(timeout);
            };
         };
         let task = response.data;
         getprogress(task, token);
         return task;
      });
}

// endpoint de excluimento de model
function deletemodel(token, modelid) {
   return http
      .post("/deletemodel", {
         token: token,
         id: modelid,
      })
      .then((response) => {
         return response;
      });
}

// endpoint de excluimento de projeto
function deleteproject(token, projectid) {
   return http
      .post("/deleteproject", {
         token: token,
         id: projectid,
      })
      .then((response) => {
         return response;
      });
}

//endpoint de excluimento de varios projetos
function deletemanyprojects(token, projectsIds) {
   return http
      .post("/deletemanyproject", {
         token: token,
         options: projectsIds,
      })
      .then((response) => {
         return response;
      });
}

// endpoint que retorna uma lista de datasets
// com id, nome e data
function getdatasetlist(token) {
   return http.post("/getproxylist", { token: token }).then((response) => {
      if (response.data) {
         let data = response.data;
         let auxData = [];
         data.forEach((element) => {
            element = {
               ...element,
               type: "dataset",
               link: "/dataset/" + element.id,
            };
            auxData.push(element);
         });
         return auxData;
      }
   });
}

// endpoint que retorna informações referentes
// a um dataset específico, a partir do seu id
//
// ws.getdataset(this.state.token, id)
//   .then(ds =>
// {
//   //use ds
// })
// ds structure:
// [{
//     FirstPageId: "string value"
//     Id: "string value"
//     Name: "string value"
//     HeaderInfo: Array
//     DataPreview: Json
//     PageCount: n
// }, ... ]
function getdataset(token, id) {
   return http
      .post("/getdataset", {
         token: token,
         id: id,
      })
      .then((response) => {
         let x = response.data;
         return {
            Id: x["id"],
            Name: x["name"],
            DataPreview: x["dataPreview"],
            HeaderInfo: x["headerInfo"]["headerinfo"],
            PageCount: x["pageCount"],
            FirstPageId: x["firstPageId"],
            ProjectId: x["projectId"],
         };
      });
}

// endpoint para remoção de colunas
function removecolumns(token, proxyid, column) {
   return http
      .post("/columnsremove", {
         token: token,
         id: proxyid,
         options: column,
      })
      .then((response) => response.data);
}

function removesourcecolumns(token, datasourceid, column) {
   return http
      .post("/sourcecolumnsremove", {
         token: token,
         id: datasourceid,
         options: column,
      })
      .then((response) => response.data);
}

// endpoint para criação de uma decision tree
function decisiontree(
   name,
   datasetid,
   trainfraction,
   maxbinstosplit,
   maxdepth,
   target,
   featuresused,
   optimizealgorithm,
   crossvalidatealgorithm,
   isregressiontree,
   mlframework
) {
   return http
      .post("/decisiontree", {
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         trainfraction: trainfraction,
         maxbinstosplit: maxbinstosplit,
         maxdepth: maxdepth,
         target: target,
         featuresused: featuresused,
         optimizealgorithm: optimizealgorithm,
         crossvalidatealgorithm: crossvalidatealgorithm,
         isregressiontree: isregressiontree,
         mlframework: mlframework,
      })
      .then((response) => {
         return response.data;
      });
}

// endpoint para criação de uma decision tree
function xgboost(
   name,
   datasetid,
   trainfraction,
   learning_rate,
   maxdepth,
   target,
   featuresused,
   optimizealgorithm,
   crossvalidatealgorithm,
   mlframework,
   subsample,
   n_estimators,
   isregressiontree
) {
   return http
      .post("/xgboost", {
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         trainfraction: trainfraction,
         learning_rate: learning_rate,
         maxdepth: maxdepth,
         target: target,
         featuresused: featuresused,
         optimizealgorithm: optimizealgorithm,
         crossvalidatealgorithm: crossvalidatealgorithm,
         mlframework: mlframework,
         subsample,
         n_estimators,
         isregressiontree,
      })
      .then((response) => {
         let task = response.data;
         return task;
      });
}

// endpoint para criação de um kmeans
function kmeans(
   name,
   datasetid,
   k,
   maxiterations,
   featuresused,
   optimizealgorithm,
   crossvalidatealgorithm,
   mlframework,
   clusterrange
) {
   return http
      .post("/kmeans", {
         mlframework: mlframework,
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         k: k,
         maxiterations: maxiterations,
         featuresused: featuresused,
         optimizealgorithm: optimizealgorithm,
         crossvalidatealgorithm: crossvalidatealgorithm,
         clusterrange: clusterrange,
      })
      .then((response) => {
         let task = response.data;
         return task;
      });
}

// endpoint para criação de uma floresta aleatória
function randomforest(
   name,
   datasetid,
   trainfraction,
   maxbinstosplit,
   maxdepth,
   numtrees,
   target,
   featuresused,
   optimizealgorithm,
   crossvalidatealgorithm,
   isregressiontree,
   mlframework
) {
   return http
      .post("/randomforest", {
         mlframework: mlframework,
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         trainfraction: trainfraction,
         maxbinstosplit: maxbinstosplit,
         maxdepth: maxdepth,
         numtrees: numtrees,
         target: target,
         featuresused: featuresused,
         optimizealgorithm: optimizealgorithm,
         crossvalidatealgorithm: crossvalidatealgorithm,
         isregressiontree: isregressiontree,
      })
      .then((response) => {
         let task = response.data;
         return task;
      });
}

// endpoint para criação de uma regressão linear
function linearregression(
   name,
   datasetid,
   trainfraction,
   numIterations,
   regParam,
   target,
   featuresused,
   optimizeAlgorithm,
   crossValidation,
   mlframework
) {
   return http
      .post("/linearregression", {
         mlframework: mlframework,
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         trainfraction: trainfraction,
         numIterations: numIterations,
         regParam: regParam,
         target: target,
         featuresused: featuresused,
         optimizeAlgorithm: optimizeAlgorithm,
         crossValidation: crossValidation,
      })
      .then((response) => {
         let task = response.data;
         return task;
      });
}

// endpoint para criação de um filtro colaborativo
function filtrocolaborativo(
   name,
   datasetid,
   numIterations,
   numRecomendations,
   userCol,
   productCol,
   ratingCol
) {
   return http
      .post("/als", {
         token: localStorage.getItem("user_token"),
         name: name,
         datasetid: datasetid,
         numiterations: numIterations,
         numrecomendations: numRecomendations,
         userCol: userCol,
         productCol: productCol,
         ratingCol: ratingCol,
      })
      .then((response) => {
         let task = response.data;
         return task;
      })
}

// endpoint que retorna uma lista de todos os projetos do usuário
function getprojectlist(token) {
   let id = "";
   return http
      .post("/getprojectlist", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response.data;
      });
}

// endpoint que retorna todos os modelos de um
// determinado dataset, a partir do id do dataset
function getdatasetmdlist(token, datasetid) {
   return http
      .post("/getdsmdlist", {
         token: token,
         id: datasetid,
      })
      .then((response) => {
         return response.data.map((md) => {
            return {
               id: md["id"],
               algorithm: md["algorithm"],
               projectid: md["projectId"],
               datasetid: md["dataProxyId"],
               date: md["date"],
               name: md["name"],
            };
         });
      });
}

// endpoint que retorna as informações de um modelo
// previamente executado e salvo no backend, e não
// que tenha acabado de ser executado (como o getresult)
function getmodel(token, modelid) {
   return http
      .post("/fillmlresult", {
         token: token,
         id: modelid,
      })
      .then((response) => {
         return response;
      });
}

// endpoint que cancela uma task quando está sendo executada
function killtask(token, taskid) {
   return http
      .post("/killtask", {
         taskid: taskid,
         token: token,
      })
      .then((response) => {
         return response.data;
      });
}

function deleteusertask(token, taskstring) {
   return http
      .post("/deleteusertask", {
         taskstring: taskstring,
         token: token,
      })
      .then((response) => {
         return response.data;
      });
}

function restarttask(token, parameters) {
   return http
      .post("/restartusertask", {
         token: token,
         taskstring: parameters,
      })
      .then((response) => {
         return response.data;
      });
}

function makeindividualprediction(token, modelid, fieldsandvalues) {
   return http
      .post("/makeprediction", {
         token: token,
         modelid: modelid,
         fieldsandvalues: fieldsandvalues,
      })
      .then((response) => {
         return response;
      });
}

function deleteallusertasks(token) {
   return http
      .post("/deleteallusertasks", {
         token: token,
      })
      .then((response) => {
         return response;
      });
}

function gettask(token, taskstring) {
   return http
      .post("/gettask", {
         token: token,
         taskstring: taskstring,
      })
      .then((response) => {
         return response;
      });
}

function getprogress(taskid, token) {
   return http
      .post("/getprogress", {
         taskid: taskid,
         token: token,
      })
      .then((response) => {
         return response;
      });
}

function getuser() {
   return http
      .get(`/getuser/${localStorage.getItem("user_token")}`)
      .then((response) => {
         return response;
      });
}

function getuserbyid(token, userid) {
   return http
      .post("/getuserbyid", {
         token: token,
         id: userid,
      })
      .then((response) => {
         return response;
      });
}

function renameuser(token, option) {
   return http
      .post("/renameuser", {
         token: token,
         option: option,
      })
      .then((response) => {
         return response.data;
      });
}

function updateuseremail(token, option) {
   return http
      .post("/updateuseremail", {
         token: token,
         option: option,
      })
      .then((response) => {
         return response.data;
      });
}

function updateuserpassword(token, option) {
   return http
      .post("/updateuserpassword", {
         token: token,
         option: option,
      })
      .then((response) => {
         return response.data;
      });
}

function getuserprofilepicture(token) {
   return http
      .post("/getuserprofilepicture", {
         token: token,
      })
      .then((response) => {
         return response;
      });
}

function updateuserprofilepicture(file, fileName, token) {
   return http
      .post("/updateuserprofilepicture", {
         file: file,
         fileName: fileName,
         token: token,
      })
      .then((response) => {
         return response;
      });
   // let imgFile = new File([file], `${fileName}`);
   // let formData = new FormData();

   // formData.append("file", imgFile);
   // formData.append("fileName", fileName);
   // formData.append("token", token);
}

function yolovideo(mediaid, datasetid, threshold, optimized) {
   return http
      .get(
         `/yolovideo/${mediaid}/${localStorage.getItem(
            "user_token"
         )}/${datasetid}/${threshold}/${optimized}`
      )
      .then((response) => {
         return response?.data;
      });
}

function yoloimage(mediaid, datasetid, threshold, optimized) {
   return http
      .get(
         `/yoloimage/${mediaid}/${localStorage.getItem(
            "user_token"
         )}/${datasetid}/${threshold}/${optimized}`
      )
      .then((response) => {
         return response?.data;
      });
}

function logout(token) {
   return http
      .post("/logout", {
         token: token,
      })
      .then((response) => {
         return response;
      });
}

function makebatchprediction(file, token, mlresultid, progressEvent) {
   let zip = new JSZIP();
   // zipa o arquivo usando o nome dele (file.name)
   zip.file(file.name, file);
   // cria um novo arquivo assíncronamente
   return zip.generateAsync({ type: "blob" }).then(function (content) {
      // cria a estrutura da requisição em formData
      let formData = new FormData();
      const zipfile = new File([content], "data.zip");
      // adiciona o arquivo, o token e o project id ao formData
      formData.append("file", zipfile);
      formData.append("token", token);
      formData.append("mlresultid", mlresultid);

      // atualiza o progresso
      let onUploadProgress = function (event) {
         if (progressEvent != null)
            progressEvent(Math.round((100 * event.loaded) / event.total) / 10);
      };

      // requisição que atualiza o progresso através de
      // getprogresstask e getprogresstoken
      let getprogress = function (gptask, gptoken) {
         const timeout = setTimeout(() => {
            http
               .post("/getprogress", {
                  taskid: gptask,
                  token: gptoken,
               })
               .then((response) => {
                  const p = parseInt(response.data);
                  if (p === -1) p = 100;
                  progressEvent(10 + (90 * p) / 100);
                  if (p < 100) getprogress(gptask, gptoken);
               })
         }, 1000);
         return () => {
            clearTimeout(timeout);
         };
      };

      // faz a requisição de upload dataset com a função onUploadProgress
      // como parâmetro, enviada na requisição e atualizada de acordo com
      // os retornos de progresso
      return http
         .post("/makebatchprediction", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress,
         })
         .then((response) => {
            let task = response.data;
            getprogress(task, token);
            return task;
         });
   });
}

function getprediction(token, id) {
   // id taskid
   return http
      .post("/getprediction", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function deleteprediction(token, id) {
   // id predictionid
   return http
      .post("/deleteprediction", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function getpredictionlistbymodelid(token, id) {
   return http
      .post("/getpredictionlistbymodelid", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function downloadmodelresult(token, id) {
   return http
      .post("/downloadmodelresult", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function downloadprediction(token, id) {
   return http
      .post("/downloadprediction", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function downloaddataset(token, id) {
   return http.post("/downloaddataset", {
      token: token,
      id: id,
   }, {
      responseType: 'blob'  // Indica que o retorno será um Blob
   })
      .then((response) => {
         return response;
      });
}

function downloaddatasource(token, id) {
   return http
      .post("/downloaddatasource", {
         token: token,
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function forgotmypassword(email) {
   return http.post(`/forgotmypassword?email=${email}`).then((response) => {
      return response;
   });
}

function changecolumntype(token, id, options) {
   return http
      .post("/changecolumntype", {
         token: token,
         id: id,
         options: options,
      })
      .then((response) => {
         return response.data;
      });
}

function changesourcecolumntype(token, id, options) {
   return http
      .post("/changesourcecolumntype", {
         token: token,
         id: id,
         options: options,
      })
      .then((response) => {
         return response.data;
      });
}

function renamecolumn(token, id, options) {
   return http
      .post("/renamecolumn", {
         token: token,
         id: id,
         options: options,
      })
      .then((response) => {
         return response.data;
      });
}
function renamesourcecolumn(token, id, options) {
   return http
      .post("/renamesourcecolumn", {
         token: token,
         id: id,
         options: options,
      })
      .then((response) => {
         return response.data;
      });
}

function updateuserinfo(token, user) {
   return http
      .post(`/updateotheruserinfo?token=${token}`, {
         firstName: "",
         surname: "",
         email: "",
         birthDate: user.Age,
         companyName: user.CompanyName,
         companyMarketField: user.CompanyMarketField,
         companyNumberOfEmployees: user.CompanyNumberOfEmployees,
         position: user.Position,
         city: user.City,
         state: user.State,
         country: user.Country,
         intendedUse: user.IntendedUse,
         howCameToKnow: user.HowCameToKnow,
         levelOfMLExperience: user.LevelOfMLExperience,
         password: "",
         id: user.UserId,
      })
      .then((response) => {
         return response.data;
      });
}

function sharemodelresult(token, modelResultId, emails) {
   return http
      .post("/sharemodelresult", {
         token: token,
         id: modelResultId,
         options: emails,
      })
      .then((response) => {
         return response.data;
      });
}

//função que faz a copia do modelo
function copymodel(token, modelID) {
   return http
      .post("/copymodel/", {
         token: token,
         id: modelID,
      })
      .then((response) => {
         return response.data;
      });
}

function deletesharedmodel(token, modelResultId, email) {
   return http
      .delete("/deletesharedmodel", {
         token: token,
         id: modelResultId,
         option: email,
      })
      .then((response) => {
         return response.data;
      });
}

function getusersbyids(token, ids) {
   return http
      .post("/getusersbyids", {
         token: token,
         options: ids,
      })
      .then((response) => {
         return response.data;
      });
}

function copydataset(token, datasetID) {
   return http
      .post("/copydataset/", {
         token: token,
         id: datasetID,
      })
      .then((response) => {
         return response;
      });
}

function emailvalidation(token, pin) {
   return http
      .post("/emailvalidation", {
         token: token,
         option: pin,
      })
      .then((response) => {
         return response;
      });
}

function renamecentroid(token, modelid, centroidNumber, centroidName) {
   return http
      .post("/renamecentroid", {
         token,
         id: modelid,
         options: [centroidNumber, centroidName],
      })
      .then((response) => {
         return response;
      });
}

function getowner(token, modelid) {
   return http
      .post("/getowner", {
         token,
         id: modelid,
      })
      .then((response) => {
         return response;
      });
}

function verifyattemptlimitcounter() {
   return http.post("/verifyattemptlimitcounter").then((response) => {
      return response;
   });
}

function verifytoken(token) {
   return http
      .post("/verifytoken", {
         token,
      })
      .then((response) => {
         return response;
      });
}

function updatetutorialdone(status) {
   return http.patch("/updatetutorialdone", {
      token: localStorage.getItem("user_token"),
      option: status,
   }).then((response) => {
      return response;
   })
}
// eslint-disable-next-line
export default {
   login,
   createaccount,
   createproject,
   uploadmongodataset,
   deletemodel,
   deleteproject,
   deletemanyprojects,
   getdatasetlist,
   getdataset,
   //getdatasource,
   getmodel,
   //gethistogram,
   getprojectlist,
   getdatasetmdlist,
   removecolumns,
   removesourcecolumns,
   decisiontree,
   xgboost,
   yoloimage,
   yolovideo,
   kmeans,
   randomforest,
   linearregression,
   filtrocolaborativo,
   killtask,
   deleteusertask,
   uploadsqldataset,
   restarttask,
   makeindividualprediction,
   deleteallusertasks,
   gettask,
   getprogress,
   getuser,
   updateuseremail,
   renameuser,
   updateuserpassword,
   getuserprofilepicture,
   updateuserprofilepicture,
   logout,
   makebatchprediction,
   getprediction,
   deleteprediction,
   getpredictionlistbymodelid,
   downloadmodelresult,
   downloadprediction,
   downloaddataset,
   downloaddatasource,
   forgotmypassword,
   changecolumntype,
   changesourcecolumntype,
   renamecolumn,
   renamesourcecolumn,
   updateuserinfo,
   sharemodelresult,
   deletesharedmodel,
   getuserbyid,
   getusersbyids,
   copymodel,
   copydataset,
   emailvalidation,
   renamecentroid,
   getowner,
   verifyattemptlimitcounter,
   verifytoken,
   updatetutorialdone
};
