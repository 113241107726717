import React, { useEffect, useState } from "react";
import WandIcon from "../../../../assets/icons/jsxIcons/WandIcon";
import { toast } from "react-toastify";
import predictionService from "../../../../services/PredictionService";
import handleErrorResponse from "../../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../../../store/slices/tasks/tasksSlice";
import "./../../../../assets/css/views/popups/predictions/prediction.css";
import { selectDataSources, setDataSources } from "../../../../store/slices/cache/cacheSlice";
import datasetService from "../../../../services/DatasetService";
import ResultBatchPrediction from "./ResultBatchPrediction";
import RunBatchPrediction from "./RunBatchPrediction";

function BatchPrediction({ modelId }) {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useDispatch();
   const datasources = useSelector(selectDataSources);
   const [isLoading, setIsLoading] = useState(false);
   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");
   const [progress, setProgress] = useState(0);
   const [columns, setColumns] = useState([]);
   const [rows, setRows] = useState([]);
   const [tab, setTab] = useState("run");
   const [hasResult, setHasResult] = useState(false);
   const [predictionId, setPredictionId] = useState("");

   useEffect(() => {
      if (datasources?.length === 0) {
         datasetService
            .getsourcelist()
            .then((data) => {
               dispatch(setDataSources(data));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            });
      }
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress < 100) {
            setProgress(100);
            predictionService
               .getprediction(taskId)
               .then((data) => {
                  setPredictionId(data.id);
                  let firstColumnObject = data?.result[0];
                  let columns = Object.keys(firstColumnObject).map((column, index) => {
                     return {
                        field: `column${index}`,
                        headerName: column,
                        width: 140,
                        editable: false,
                        sortable: false,
                        filterable: false,
                     };
                  });
                  setColumns(columns);

                  let rows = data?.result.map((row, index) => {
                     let rowObject = {};
                     Object.keys(row).map((column, index) => {
                        rowObject[`column${index}`] = row[column];
                     });
                     return { id: index, ...rowObject };
                  });
                  setRows(rows);
                  setTab("result");
                  setHasResult(true);
                  setIsLoading(false);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
   }, [dispatch, history, t, taskList]);

   const TabsComponent = () => {
      return (
         <div className="tabs">
            <button className={`tab ${tab === "result" ? "active" : ""}`} onClick={() => setTab("result")}>
               {t("popup.predictions.tabs.result")}
            </button>
            <button className={`tab ${tab === "run" ? "active" : ""}`} onClick={() => setTab("run")}>
               {t("popup.predictions.tabs.run_again")}
            </button>
         </div>
      );
   };

   return (
      <>
         {hasResult && <TabsComponent />}
         {tab === "result" ? (
            <ResultBatchPrediction rows={rows} columns={columns} predictionId={predictionId} />
         ) : (
            <RunBatchPrediction
               datasources={datasources}
               modelId={modelId}
               setTaskId={setTaskId}
               isLoading={isLoading}
               setIsLoading={setIsLoading}
            />
         )}
      </>
   );
}

export default BatchPrediction;
