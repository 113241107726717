import "../../assets/css/graphicsComponents/modelsRepresentations/collaborativefilteringtable.css";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";

export const CollaborativeFilteringTable = ({ modelResult, fieldsUsed }) => {
  
  const productid = fieldsUsed[1];

  // eslint-disable-next-line
  // modelResult.Recommendations.map((value, key) => {
  //   var productId = [];
  //   var rating = [];
  //   var userId = 0;
  //   userId = value[userid];
  //   value.recommendations.forEach((valor, chave) => {
  //     productId[chave] = valor[productid];
  //     rating[chave] = Math.round(valor[ratingvalue] * 100) / 100;
  //   });
  //   listao.push({ userId, productId, rating });
  // });

  const { t } = useTranslation();

  let list = modelResult.Recommendations;

  return (
    <div className="collaborative-filtering-table">
      {list.map((object, index1) => {
        return (
          <table key={index1}>
            <thead>
              <tr>
                <th><p>{t("model.als.user")}</p></th>
                <th id="user-id">
                  <span></span>
                  <p>{index1 + 1}</p>
                </th>
              </tr>
              <tr>
                <th><p>{t("model.als.product")}</p></th>
                <th><p>{t("model.als.rating")}</p></th>
              </tr>
            </thead>
            <tbody>
              {object.recommendations.map((recomendations, index2) => {
                return (
                  <tr key={index2}>
                    {/* <th><p>{recomendations.timestamp}</p></th>
                    <th><p>{recomendations.rating}</p></th> */}
                    <th><p>{recomendations[productid]}</p></th>
                    <th><p>{Math.round(recomendations.rating * 100) / 100}</p></th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        );
      })}
    </div>
  );

};
