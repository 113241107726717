import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";

highcharts3d(Highcharts); //init module

//Give the points a 3D feel by adding a radial gradient
Highcharts.setOptions({
   colors: Highcharts.getOptions().colors.map(function (color) {
      return {
         radialGradient: {
            cx: 0.4,
            cy: 0.3,
            r: 0.5,
         },
         stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.2).get("rgb")],
         ],
      };
   }),
});

let chart = null;

const randomColor = (() => {
   const randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
   };

   return () => {
      let h = randomInt(0, 360);
      let s = randomInt(42, 98);
      let l = randomInt(40, 90);
      return `hsl(${h},${s}%,${l}%)`;
   };
})();

function create(model, graphics, categorical, usedCategorical) {
   let seriesWithColor = [];
   let pca_data = graphics;
   let series = [[[[]]]];
   let fieldUsed = -1;

   if (categorical?.encoder) {
      for (let key in categorical.encoder) {
         let obj = categorical.encoder[key];
         if (obj.hasOwnProperty(usedCategorical)) {
            fieldUsed = obj[usedCategorical];
            break;
         }
      }
   }

   let clusterValueMap = [];
   for (let i = 0; i < pca_data?.length; i++) {
      const prediction = pca_data[i].prediction;
      const position = pca_data[i].position;
      if (!series[prediction]) {
         series[prediction] = [];
      }
      if (fieldUsed !== -1) {
         let categoricalValue = pca_data[i].values[fieldUsed];
         let decodedValue = categoricalValue;
         if (categorical?.encoder && typeof categoricalValue === "number") {
            for (let key in categorical.encoder) {
               const obj = categorical.encoder[key];
               const keyObj = Object.keys(obj)[0];
               const index = obj[keyObj];
               if (index !== fieldUsed) continue;
               decodedValue = categorical[keyObj][categoricalValue];
               pca_data[i].values[fieldUsed] = decodedValue;
            }
         }
         categoricalValue = decodedValue;
         if (!clusterValueMap.includes(decodedValue)) {
            if (typeof decodedValue === "number") {
               clusterValueMap.push(decodedValue.valueOf());
            } else {
                clusterValueMap.push(decodedValue);
            }
         }
         const indexOfCategoricalValue = clusterValueMap.indexOf(categoricalValue);
         if (!series[prediction][indexOfCategoricalValue]) {
            series[prediction][indexOfCategoricalValue] = [];
         }
         series[prediction][indexOfCategoricalValue].push(position);
      } else {
         if (!series[prediction][0]) {
            series[prediction][0] = [];
         }
         series[prediction][0].push(position);
      }
   }

   for (let i = 0; i < series.length; i++) {
      if (series[i] == null) {
         continue;
      }
      for (let j = 0; j < series[i].length; j++) {
         if (series[i][j] == null) {
            continue;
         }
         if (series[i][j].length === 1 && (series[i][j][0] === null || series[i][j][0].length === 0)) {
            series[i][j] = null;
         }
      }
   }

   for (let i = 0; i < series.length; i++) {
      if (!series[i]) continue;
      let color = randomColor();
      for (const element of series[i]) {
         if (!element) continue;
         seriesWithColor.push({
            name: model.centers[i].name,
            colorByPoint: false,
            accessibility: { exposeAsGroupOnly: true },
            color: color,
            data: element,
         });
      }
   }

   chart = new Highcharts.Chart({
      chart: {
         renderTo: "container",
         margin: 10,
         type: "scatter3d",
         animation: false,
         backgroundColor: "#FDFDFD",
         options3d: {
            enabled: true,
            alpha: 10,
            beta: 30,
            depth: 500,
            viewDistance: 5,
            fitToPlot: false,
         },
      },
      title: {
         text: "",
      },
      subtitle: {
         text: "",
      },
      tooltip: {
         formatter: function () {
            let coordinates = [
               this.point.options.x,
               this.point.options.y,
               this.point.options.z
            ];

            let dots = pca_data.find(element => {
               return coordinates[0] === element.position[0] &&
                   coordinates[1] === element.position[1] &&
                   coordinates[2] === element.position[2];
            }) || [];

            let values = dots?.values;

            if (!(values instanceof Array)) { return;}

            if (categorical?.encoder) {
               for (let key in categorical.encoder) {
                  const obj = categorical.encoder[key];
                  const keyObj = Object.keys(obj)[0];
                  const index = obj[keyObj];
                  const labelValue = values[index];
                  if (typeof labelValue === "number") {
                     values[index] = categorical[keyObj][labelValue];
                  }
               }
            }

            model.Fields = model.Fields.map(field => field.replace("indexed", ""));

            return values.map((value, index) =>
                `${model.Fields[index]}: <b>${value}</b><br>`
            ).join("");
         },
      },
      plotOptions: {
         scatter: {
            width: 10,
            height: 10,
            depth: 10,
         },
      },
      yAxis: {
         min: model.pca.axis[1][1],
         max: model.pca.axis[1][0],
         title: null,
         visible: false,
      },
      xAxis: {
         min: model.pca.axis[0][1],
         max: model.pca.axis[0][0],
         visible: false,
      },
      zAxis: {
         min: model.pca.axis[2][1],
         max: model.pca.axis[2][0],
         showFirstLabel: false,
         visible: false,
      },
      accessibility: {
         enabled: false,
      },
      legend: {
         enabled: true,
      },
      series: seriesWithColor,
   });
   // Add mouse and touch events for rotation
   (function (H) {
      function dragStart(eStart) {
         eStart = chart.pointer.normalize(eStart);

         let posX = eStart.chartX,
            posY = eStart.chartY,
            alpha = chart.options.chart.options3d.alpha,
            beta = chart.options.chart.options3d.beta,
            sensitivity = 5, // lower is more sensitive
            handlers = [];

         function drag(e) {
            // Get e.chartX and e.chartY
            e = chart.pointer.normalize(e);

            chart.update(
               {
                  chart: {
                     options3d: {
                        alpha: alpha + (e.chartY - posY) / sensitivity,
                        beta: beta + (posX - e.chartX) / sensitivity,
                     },
                  },
               },
               undefined,
               undefined,
               false
            );
         }

         function unbindAll() {
            handlers.forEach(function (unbind) {
               if (unbind) {
                  unbind();
               }
            });
            handlers.length = 0;
         }

         handlers.push(H.addEvent(document, "mousemove", drag));
         handlers.push(H.addEvent(document, "touchmove", drag));

         handlers.push(H.addEvent(document, "mouseup", unbindAll));
         handlers.push(H.addEvent(document, "touchend", unbindAll));
      }
      H.addEvent(chart.container, "mousedown", dragStart);
      H.addEvent(chart.container, "touchstart", dragStart);
   })(Highcharts);
}

function destroy() {
   if (chart) {
      chart.destroy();
   }
}

const KmeansHighcharts = {
   create,
   destroy,
};

export default KmeansHighcharts;
