import React, { useState } from 'react';
import FocusIcon from "../creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/FocusIcon";
import WandIcon from "../../../assets/icons/jsxIcons/WandIcon";
import { toast } from "react-toastify";
import predictionService from "../../../services/PredictionService";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import "./../../../assets/css/views/popups/predictions/prediction.css"
import { Loader } from 'semantic-ui-react';
import { setNeedUpdateModelResult } from '../../../store/slices/cache/cacheSlice';
import { useDispatch } from 'react-redux';

function SinglePrediction({ modelId, fields, targetName }) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()
    const [prediction, setPrediction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const filterChar = (value) => {
        const index = value.length - 1;
        if (index >= 0) {
            const char = value[index];
            if ((char.charCodeAt() < 48 || char.charCodeAt() > 57) && char.charCodeAt() !== 46) {
                value = value.slice(0, index);
            }
        }
        return value;
    };

    const fieldsList = () => {
        return fields?.usedFields?.map((name) => {
            let object = fields?.categoricalFields?.find((element) => element?.Feature === name);
            if (!object) {
                return (
                    <div key={name} className="field">
                        <p>{name}</p>
                        <input
                            onChange={(e) => { e.target.value = filterChar(e.target.value) }}
                            id="field-input"
                            type="text"
                            placeholder="0.0"
                        />
                    </div>
                );
            }
            object = object?.Values;
            return (
                <div key={name} className="field">
                    <p>{name}</p>
                    <select id="field-select">
                        {object?.map((field) => {
                            return <option key={field}>{field}</option>;
                        })}
                    </select>
                </div>
            );
        });
    };

    const makePrediction = () => {
        if (!isLoading) {
            let fields = document.querySelectorAll(".field");
            let fields_values = {};

            fields.forEach((field) => {
                field = field.children;
                fields_values = {
                    ...fields_values,
                    [field[0].textContent]: isNaN(field[1]?.value) ? field[1]?.value : parseFloat(field[1]?.value),
                };
            });

            setIsLoading(true);
            predictionService.makesingleprediction(modelId, fields_values)
                .then((data) => {
                    setPrediction(data?.Prediction);
                    toast.success(t('popup.predictions.success.makeprediction'));
                    dispatch(setNeedUpdateModelResult(true));
                })
                .catch((error) => {
                    handleErrorResponse(history, error, t);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div className="single-prediction">
            <div className="target">
                <FocusIcon style={{ color: "#808080" }} />
                <p>{targetName}</p>
            </div>
            <p>{t('popup.predictions.description')}</p>
            <div className='fields-list'>{fieldsList()}</div>
            <button onClick={makePrediction} disabled={isLoading} className='button-prediction'>
                {isLoading ? <Loader active inline size='tiny' /> : <WandIcon />}
            </button>
            <div className="answer">
                <p id="attribute">{targetName}</p>
                {prediction && <div className="field"><p>{prediction}</p></div>}
            </div>
        </div>
    );
}

export default SinglePrediction;
