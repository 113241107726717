import { useEffect, useState } from "react";
import data_parse from "../../helpers/data_parse";
import "./../../assets/css/views/screens/history.css";
import { useHistory } from "react-router-dom";

import { Popup } from "./../../components/Popup";
import DeleteGenericPopup from "../popups/actions/DeleteGenericPopup";
import RefreshIcon from "./../../assets/icons/jsxIcons/RefreshIcon";
import CheckIcon from "../../assets/icons/jsxIcons/CheckIcon";
import CloseIcon from "../../assets/icons/jsxIcons/CloseIcon";
import HistoryLoadingIcon from "./../../assets/icons/jsxIcons/HistoryLoadingIcon";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";

import ArrowDownIcon from "./../../assets/icons/jsxIcons/ArrowDownIcon";
import ArrowRightIcon from "./../../assets/icons/jsxIcons/ArrowRightIcon";
import DecisionTreeIcon from "../../assets/icons/jsxIcons/DecisionTreeIcon";
import RandomForestIcon from "../../assets/icons/jsxIcons/RandomForestIcon";
import LinearRegressionIcon from "../../assets/icons/jsxIcons/LinearRegressionIcon";
import SVMIcon from "../../assets/icons/jsxIcons/SVMIcon";
import XGBoostIcon from "../popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/XGBoostIcon";
import MultilayerPerceptronIcon from "../../assets/icons/jsxIcons/MultilayerPerceptronIcon";
import AnomaliesIcon from "../../assets/icons/jsxIcons/AnomaliesIcon";
import AssociationDiscoveryIcon from "../../assets/icons/jsxIcons/AssociationDiscoveryIcon";
import KmeansIcon from "../../assets/icons/jsxIcons/KmeansIcon";
import TableIcon from "../../assets/icons/jsxIcons/TableIcon";
import DatasetCSVIcon from "./../../assets/icons/jsxIcons/DatasetCSVIcon";
import { useSelector, useDispatch } from "react-redux";
import taskService from "../../services/TaskService";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import HistoryCard from "../../components/HistoryCard";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import { toast } from "react-toastify";

function History() {
   const { t } = useTranslation();

   const dispatch = useDispatch();
   const [triggerDeleteTask, setTriggerDeleteTask] = useState(false);
   const [deleteTaskPopup, setDeleteTaskPopup] = useState(false);

   const history = useHistory();

   const [taskUpdate, setTaskUpdate] = useState(false);
   const [models, setModels] = useState([]);
   const [datasets, setDatasets] = useState([]);

   const getusertasks = () => {
      if (!taskUpdate) {
         setTaskUpdate(true);
         taskService
            .getusertasks()
            .then((data) => {
               let auxModels = [];
               let auxDatasets = [];
               const datasetIcon = getAlgorithmIcon("dataset");
               data.forEach((element) => {
                  const date = data_parse.parseDate(element.date);
                  if (element.type === "model") {
                     auxModels.push({
                        ...element,
                        date: date,
                        algorithm: getAlgorithmIcon(element.algorithm),
                     });
                  } else {
                     auxDatasets.push({
                        ...element,
                        icon: datasetIcon,
                        date: date,
                     });
                  }
               });
               setModels(auxModels);
               setDatasets(auxDatasets);
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setTaskUpdate(false);
            });
      }
   };

   useEffect(() => {
      if (triggerDeleteTask) {
         taskService
            .deleteusertask(triggerDeleteTask)
            .then(() => {
               getusertasks();
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setTriggerDeleteTask(false);
            });
      }
   }, [triggerDeleteTask]);

   const [activeHistoryType, setActiveHistoryType] = useState(1);

   useEffect(() => {
      const historyModels = document.getElementById("models");
      const historyDatasets = document.getElementById("datasets");
      if (activeHistoryType === 1) {
         historyModels.style.display = "flex";
         historyDatasets.style.display = "none";
      } else if (activeHistoryType === 2) {
         historyModels.style.display = "none";
         historyDatasets.style.display = "flex";
      } else {
         historyModels.style.display = "none";
         historyDatasets.style.display = "none";
      }
   }, [activeHistoryType]);

   function getAlgorithmIcon(type) {
      if (type === "decisiontree") {
         return <DecisionTreeIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "linearregression") {
         return <LinearRegressionIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "randomforest") {
         return <RandomForestIcon style={{ size: 22, color: "#22577A" }} />;
      } else if (type === "kmeans") {
         return <KmeansIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "filtrocolaborativo") {
         return <TableIcon style={{ size: 22, color: "#22577A" }} />;
      } else if (type === "gradientboosting") {
         return <XGBoostIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "multilayerperceptron") {
         return <MultilayerPerceptronIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "svm") {
         return <SVMIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "isolationforest") {
         return <AnomaliesIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "fpgrowth") {
         return <AssociationDiscoveryIcon style={{ size: 22, color: "#38A3A5" }} />;
      } else if (type === "dataset") {
         return (
            <DatasetCSVIcon
               style={{
                  size: 22,
                  color: type === "image" ? "#38A3A5" : "#282828",
               }}
            />
         );
      }
      return "";
   }

   const taskList = useSelector((state) => state.tasks.taskList);

   useEffect(() => {
      getusertasks();
   }, []);

   useEffect(() => {
      if (taskList.length > 0) {
         let modelList = models;
         if (
            modelList.find((model) => {
               if (model.status) return model;
            })
         ) {
            modelList = modelList.map((model) => {
               const task = taskList.find((task) => task.task === model.taskid);
               if (task) {
                  model.status = task.progress;
               } else {
                  model.status = false;
               }
               return model;
            });
            setModels(modelList);
         }
      } else {
         getusertasks();
      }
   }, [taskList]);

   function restarttask(id) {
      taskService
         .restartusertask(id)
         .then(() => {
            dispatch(setRunningTask(true));
            getusertasks();
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   }

   const deletealltask = () => {
      taskService
         .deleteallusertasks()
         .then((data) => {
            setDatasets([]);
            setModels([]);
            toast.success(t(`backend.SUCCESS.${data}`));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   };

   return (
      <>
         <div className="container-history">
            <header>
               <article>
                  <p id="title">{t("history.header.title")}</p>
                  <p>{t("history.header.description")}</p>
               </article>
            </header>
            <button
               disabled={!datasets.length && !models.length}
               className={!datasets.length && !models.length ? "delete-tasks delete-tasks-disabled" : "delete-tasks"}
               onClick={deletealltask}
            >
               <p>{t("components.tasks.button.deleteall")}</p>
               <DeleteForeverOutlinedIcon style={{ color: "#FFFFFF", width: 24, height: 24 }} />
            </button>
            <main>
               <section>
                  <button
                     onClick={() => {
                        setActiveHistoryType(activeHistoryType === 1 ? 0 : 1);
                     }}
                  >
                     <span>{t("history.models.title")}</span>
                     <span>{activeHistoryType === 1 ? <ArrowDownIcon /> : <ArrowRightIcon />}</span>
                  </button>
                  <div id="models">
                     <div className="history-models">
                        {models.length === 0 ? (
                           <div className="no-task">
                              <p>{t("project.info.no_data")}</p>
                           </div>
                        ) : (
                           <table>
                              <thead>
                                 <tr>
                                    <th id="algorithm">
                                       <p>{t("history.models.nav.name_4")}</p>
                                    </th>
                                    <th id="name">
                                       <p>{t("history.models.nav.name_1")}</p>
                                    </th>
                                    <th id="project">
                                       <p>{t("history.models.nav.name_2")}</p>
                                    </th>
                                    <th id="dataset">
                                       <p>{t("history.models.nav.name_3")}</p>
                                    </th>
                                    <th id="date">
                                       <p>{t("history.models.nav.name_5")}</p>
                                    </th>
                                    <th id="size">
                                       <p>{t("history.models.nav.name_6")}</p>
                                    </th>
                                    <th id="status">
                                       <p>{t("history.models.nav.name_7")}</p>
                                    </th>
                                    <th id="restart">
                                       <p></p>
                                    </th>
                                    <th id="button">
                                       <p></p>
                                    </th>
                                    <th id="trash"></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {models.map((model, index) => {
                                    return (
                                       <tr key={index}>
                                          <th id="algorithm">{model.algorithm}</th>
                                          <th id="name">{model.name}</th>
                                          <th id="project">{model.project}</th>
                                          <th id="dataset">{model.dataset}</th>
                                          <th id="date">{model.date}</th>
                                          <th id="size">{model.size}</th>
                                          <th id="status">
                                             {model.status ? (
                                                <div>
                                                   <HistoryLoadingIcon style={{ size: 35, color: "#7f7f7f" }} />
                                                   <p>{model.status}%</p>
                                                </div>
                                             ) : model.message || !model.result ? (
                                                <CloseIcon />
                                             ) : (
                                                <CheckIcon />
                                             )}
                                          </th>
                                          <th id="restart">
                                             <button
                                                style={model.result || model.status ? { display: "none" } : {}}
                                                disabled={model.result || model.status}
                                                className={!model.result && !model.status ? "restart" : "undefined"}
                                                onClick={() => {
                                                   restarttask(model.taskid);
                                                }}
                                             >
                                                <RefreshIcon style={{ size: 18, color: "white" }} />
                                             </button>
                                          </th>
                                          <th id="button">
                                             <button
                                                className={
                                                   !model.status
                                                      ? model.message
                                                         ? "cancel"
                                                         : model.result
                                                         ? "open"
                                                         : "undefined"
                                                      : "cancel"
                                                }
                                                onClick={(e) => {
                                                   e.preventDefault();
                                                   if (model.message) {
                                                      const type = model.message.includes("_ALERT_")
                                                         ? "ALERT"
                                                         : "ERROR";
                                                      toast.error(t(`backend.${type}.${model.message}`));
                                                   } else if (!model.status && model.id) {
                                                      history.push(`model/${model.id}`);
                                                   } else if (model.status) {
                                                      taskService.killtask(model.taskid);
                                                   }
                                                }}
                                             >
                                                <p>
                                                   {!model.status
                                                      ? model.message
                                                         ? t("history.type_button.type_3")
                                                         : model.result
                                                         ? t("history.type_button.type_1")
                                                         : t("history.type_button.type_4")
                                                      : t("history.type_button.type_2")}
                                                </p>
                                             </button>
                                          </th>
                                          <th id="trash">
                                             <button
                                                onClick={() => {
                                                   setDeleteTaskPopup(model.taskid);
                                                }}
                                             >
                                                <DeleteForeverOutlinedIcon
                                                   style={{ color: "#282828", width: 24, height: 24 }}
                                                />
                                             </button>
                                          </th>
                                       </tr>
                                    );
                                 })}
                              </tbody>
                           </table>
                        )}
                     </div>
                     <div className="history-models-mobile">
                        {models.length > 0 ? (
                           <>
                              {models.map((model, index) => {
                                 return (
                                    <HistoryCard
                                       content={model}
                                       index={index}
                                       setTaskDelete={setTriggerDeleteTask}
                                       getusertasks={getusertasks}
                                       key={model.taskid}
                                    />
                                 );
                              })}
                           </>
                        ) : (
                           <div className="no-task">
                              <p>{t("project.info.no_data")}</p>
                           </div>
                        )}
                     </div>
                  </div>
               </section>
               <section>
                  <button
                     onClick={() => {
                        setActiveHistoryType(activeHistoryType === 2 ? 0 : 2);
                     }}
                  >
                     <span>Datasets</span>
                     <span>{activeHistoryType === 2 ? <ArrowDownIcon /> : <ArrowRightIcon />}</span>
                  </button>
                  <div id="datasets">
                     <div className="history-datasets">
                        {datasets.length === 0 ? (
                           <div className="no-task">
                              <p>{t("project.info.no_data")}</p>
                           </div>
                        ) : (
                           <table>
                              <thead>
                                 <tr>
                                    <th id="icon"></th>
                                    <th id="name">
                                       <p>{t("history.models.nav.name_3")}</p>
                                    </th>
                                    <th id="date">
                                       <p>{t("history.models.nav.name_5")}</p>
                                    </th>
                                    <th id="size">
                                       <p>{t("history.models.nav.name_6")}</p>
                                    </th>
                                    <th id="status">
                                       <p>{t("history.models.nav.name_7")}</p>
                                    </th>
                                    <th id="button"></th>
                                    <th id="trash"></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {datasets.map((dataset, index) => {
                                    return (
                                       <tr key={index}>
                                          <th id="icon">{dataset.icon}</th>
                                          <th id="name">
                                             <p>{dataset.name}</p>
                                          </th>
                                          <th id="date">
                                             <p>{dataset.date}</p>
                                          </th>
                                          <th id="size">
                                             <p>{dataset.size}</p>
                                          </th>
                                          <th id="status">
                                             {dataset.status ? (
                                                <div>
                                                   <HistoryLoadingIcon style={{ size: 35, color: "#7f7f7f" }} />
                                                   <p>{dataset.status}%</p>
                                                </div>
                                             ) : dataset.message ? (
                                                <CloseIcon />
                                             ) : (
                                                <CheckIcon />
                                             )}
                                          </th>
                                          <th id="button">
                                             <button
                                                className={
                                                   !dataset.status
                                                      ? dataset.message
                                                         ? "cancel"
                                                         : dataset.result
                                                         ? "open"
                                                         : "undefined"
                                                      : "cancel"
                                                }
                                                disabled={false}
                                                onClick={(e) => {
                                                   e.preventDefault();
                                                   if (dataset.message) {
                                                      handleErrorResponse(history, dataset.message, t);
                                                   } else if (!dataset.status && dataset.id) {
                                                      history.push(`${dataset.type}/${dataset.id}`);
                                                   } else if (dataset.status) {
                                                      taskService.killtask(dataset.taskid);
                                                   }
                                                }}
                                             >
                                                <p>
                                                   {!dataset.status
                                                      ? dataset.message
                                                         ? t("history.type_button.type_3")
                                                         : dataset.result
                                                         ? t("history.type_button.type_1")
                                                         : t("history.type_button.type_4")
                                                      : t("history.type_button.type_2")}
                                                </p>
                                             </button>
                                          </th>
                                          <th id="trash">
                                             <button
                                                onClick={() => {
                                                   setDeleteTaskPopup(dataset.taskid);
                                                }}
                                             >
                                                <DeleteForeverOutlinedIcon
                                                   style={{ color: "#282828", width: 24, height: 24 }}
                                                />
                                             </button>
                                          </th>
                                       </tr>
                                    );
                                 })}
                              </tbody>
                           </table>
                        )}
                     </div>
                     <div className="history-datasets-mobile">
                        {datasets.length > 0 ? (
                           <>
                              {datasets.map((model, index) => {
                                 return (
                                    <HistoryCard
                                       content={model}
                                       index={index}
                                       setTaskDelete={setTriggerDeleteTask}
                                       getusertasks={getusertasks}
                                       key={model.taskid}
                                    />
                                 );
                              })}
                           </>
                        ) : (
                           <div className="no-task">
                              <p>{t("project.info.no_data")}</p>
                           </div>
                        )}
                     </div>
                  </div>
               </section>
            </main>
         </div>
         <Popup trigger={deleteTaskPopup} setTrigger={setDeleteTaskPopup}>
            <DeleteGenericPopup
               setTrigger={setDeleteTaskPopup}
               onClickDelete={() => {
                  setTriggerDeleteTask(deleteTaskPopup);
               }}
            />
         </Popup>
      </>
   );
}

export default History;
