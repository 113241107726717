import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import predictionService from "../../../../services/PredictionService";
import { toast } from "react-toastify";
import handleErrorResponse from "../../../../helpers/handleErrorResponse";
import DownloadIcon from "../../../../assets/icons/jsxIcons/DownloadIcon";
import { Button } from "../../../../components/Button";

const ResultBatchPrediction = ({ rows, columns, predictionId }) => {
   const { t } = useTranslation();
   const history = useHistory();

   const downloadPrediction = () => {
      if (predictionId) {
         predictionService
            .predictiondownload(predictionId)
            .then((response) => {
               const blob = new Blob([response], { type: "text/csv" });
               const url = window.URL.createObjectURL(blob);

               const link = document.createElement("a");
               link.href = url;
               link.setAttribute("download", "prediction.csv"); // Nome do arquivo

               document.body.appendChild(link);
               link.click();

               link.parentNode.removeChild(link);
               toast.success(t("popup.predictions.success.download"));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
               toast.error(t("popup.predictions.error.download"));
            });
      } else {
         toast.error(t("popup.predictions.error.download"));
      }
   };

   const link = "popup.predictions.generatedpredictions";

   return (
      <>
         <DataGrid rows={rows} columns={columns} pageSize={5} autoHeight />
         <Button buttonSize="btn--auto" buttonStyle="btn--primary--outline" onClick={() => downloadPrediction()}>
            <DownloadIcon />
            <p>{t(`${link}.download`)}</p>
         </Button>
      </>
   );
};

export default ResultBatchPrediction;
