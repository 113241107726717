import React, { useEffect, useState } from "react";
import { DataGrid, ptBR, esES, frFR } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import datasetService from "../../services/DatasetService";
import {
   selectDataSetsContent,
   selectDataSourcesContent,
   setNeedGetAllData,
   setNeedUpdateDataSource,
   setNeedUpdateDataset,
} from "../../store/slices/cache/cacheSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomColumnMenu from "../GridData/CustomColumnMenu";
import handleErrorResponse from "../../helpers/handleErrorResponse";

const useStyles = makeStyles({
   root: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1em",
   },
});

const Data = () => {
   const { t, i18n } = useTranslation();
   const language = i18n.language;

   const dispatch = useDispatch();

   const location = useLocation();

   const isDataSourcePage = location.pathname.includes("datasource");

   const pageId = location.pathname.split("/").pop();

   const classes = useStyles();

   const [columns, setColumns] = useState([]);

   const [cellValueSelected, setCellValueSelected] = useState("");

   const [rows, setRows] = useState([]);

   const history = useHistory();

   const cachedData = useSelector(isDataSourcePage ? selectDataSourcesContent : selectDataSetsContent).find(
      (dataSource) => dataSource.id === pageId,
   );

   const [isLoading, setIsLoading] = useState(true);

   const handleColumns = (data) => {
      if (data.headerInfo?.length > 0) {
         let columns = data.headerInfo.map((column) => {
            return {
               field: column.Nome,
               headerName: column.Nome,
               width: 140,
               editable: true,
               sortable: true,
               filterable: true,
               tipo: column?.Tipo,
               type: column?.Tipo === "Numeric" ? "number" : "string",
               id: column.Id,
            };
         });

         return columns;
      } else {
         return [];
      }
   };

   const handleRows = (data) => {
      if (data?.datasetData?.length > 0) {
         return data.datasetData.map((row) => ({
            id: row._id,
            ...row,
         }));
      } else if (data?.datasourceData?.length > 0) {
         return data.datasourceData.map((row) => ({
            id: row._id,
            ...row,
         }));
      } else {
         return [];
      }
   };

   const handleSuccessResponse = (response) => {
      toast.success(t(`backend.SUCCESS.${response}`));
      isDataSourcePage ? dispatch(setNeedUpdateDataSource(true)) : dispatch(setNeedUpdateDataset(true));
   };

   const handleChangeCell = async (params, event) => {
      const rowId = params.id;
      const field = params.field;
      const newValue = params.value;

      // eslint-disable-next-line eqeqeq
      if (cellValueSelected != newValue) {
         const previousValue = rows.find((row) => row.id === rowId)[field]; // valor anterior da célula pra caso de erro
         try {
            const response = isDataSourcePage
               ? await datasetService.changeDataSourceCell(pageId, rowId, field, newValue)
               : await datasetService.changeDataProxyCell(pageId, rowId, field, newValue);
            handleSuccessResponse(response);
         } catch (error) {
            setRows((prevRows) => prevRows.map((row) => (row.id === rowId ? { ...row, [field]: previousValue } : row)));
            handleErrorResponse(history, error, t);
            if (error.response.data === "_ERROR_111") {
               toast.error(t(`backend.ERROR.${error.response.data}`) + field);
            }
         }
      }
   };

   useEffect(() => {
      if (cachedData) {
         setColumns(handleColumns(cachedData));
         setRows(handleRows(cachedData));
         setIsLoading(false);
      }
   }, [cachedData]);

   let localeText;

   switch (language) {
      case "pt_br":
      case "pt_pt":
         localeText = ptBR.props.MuiDataGrid.localeText;
         break;
      case "es_es":
         localeText = esES.props.MuiDataGrid.localeText;
         break;
      case "fr_fr":
         localeText = frFR.props.MuiDataGrid.localeText;
         break;

      default:
         break;
   }

   return (
      <div className="data-table" style={{ maxWidth: "98%" }}>
         <DataGrid
            localeText={localeText}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowCount={cachedData?.numberOfItems}
            disableSelectionOnClick
            isRowSelectable={true}
            onPageChange={() => dispatch(setNeedGetAllData(true))}
            onSortModelChange={() => dispatch(setNeedGetAllData(true))}
            onFilterModelChange={() => dispatch(setNeedGetAllData(true))}
            onCellEditCommit={handleChangeCell}
            onCellDoubleClick={(params) => setCellValueSelected(params.value)}
            autoHeight
            components={{
               ColumnMenu: (props) => <CustomColumnMenu {...props} />,
            }}
            classes={{
               root: classes.root,
            }}
            loading={isLoading}
         />
      </div>
   );
};

export default Data;
