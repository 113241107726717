import "../../../assets/css/views/models/IsolationForest/isolationforest.css";

import AnomaliesIcon from "../../../assets/icons/jsxIcons/AnomaliesIcon";
import FileIcon from "../../../assets/icons/jsxIcons/FileIcon";
import LoadingWheel from "../../../components/LoadingWheel";
import InputSlider from "../../../components/Slider";
import { Checkbox } from "../../../components/Checkbox";

import { Popup } from "../../../components/Popup";
import { MetricsPopup } from "../../popups/informations/MetricsPopup";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../../store/slices/tasks/tasksSlice";
import { setNewNotification } from "../../../store/slices/notification/notificationSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import modelService from "../../../services/ModelService";
import IsolationForestHighcharts from "../../../graphicsComponents/modelsRepresentations/IsolationForestHighchart";
import Anomalies from "./Anomalies";
import SaveStatus from "../../../components/SaveStatus";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import { setModelsResult } from "../../../store/slices/cache/cacheSlice";
import ModeTabs from "../../../components/ModeTabs";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";

function IsolationForest({
   model,
   setModel,
   setIsCreated,
   isCreated,
   setGeneratedPredictionsPopup,
   setDataSetExists,
   dataSetExists,
}) {
   const [readOnly, setReadOnly] = useState(isCreated);
   const { t } = useTranslation();

   const [numAnomalies, setNumAnomalies] = useState(20);
   const [randomState, setRandomState] = useState(false);
   const [bootStrap, setBootStrap] = useState(false);
   const [warmStart, setWarmStart] = useState(false);
   const [maxFeatures, setMaxFeatures] = useState(1.0);
   const [metricsPopup, setMetricsPopup] = useState(false);
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   const [progress, setProgress] = useState(0);
   const [taskId, setTaskId] = useState("");
   const dispatch = useDispatch();
   const history = useHistory();
   const { parameters } = model;
   const taskList = useSelector((state) => state.tasks.taskList);

   const [anomalies, setAnomalies] = useState([]);
   const [selectedAnomalie, setSelectedAnomalie] = useState(-1);
   const frameworkOptions = [{ label: "Scikit-learn", value: "sklearn" }];

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress < 100) {
            setTaskId("");
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         setAnomalies(
            model?.result.map((data) => {
               return {
                  score: parseFloat(Math.abs(data.anomaly_score * 100).toFixed(2)),
                  info: data.info,
               };
            }),
         );
         setSelectedAnomalie(1);
      } else {
         setIsCreated(false);
      }
      // eslint-disable-next-line
   }, [model]);

   useEffect(() => {
      if (selectedAnomalie > 0) {
         let name_series = {
            name_1: t("model.isolationforest.series.name_1"),
            name_2: t("model.isolationforest.series.name_2"),
            name_3: t("model.isolationforest.series.name_3"),
         };
         IsolationForestHighcharts.create(model?.result[selectedAnomalie - 1].graph, name_series);
      }
      // eslint-disable-next-line
   }, [selectedAnomalie]);

   function createIsolationForest() {
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: null,
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         modelService
            .isolationforest({
               datasetid: model?.datasetId,
               name: model?.name,
               featuresused: model?.fields?.usedFields.toString(),
               numberofanomalies: numAnomalies,
               randomstate: randomState,
               maxfeatures: maxFeatures,
               bootstrap: bootStrap,
               warmstart: warmStart,
            })
            .then((id) => {
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   }

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  {/* Nome do algoritmo */}
                  <div className="name">
                     <AnomaliesIcon style={{ size: 24, color: "#282828" }} />
                     <p id="algorithm-name">ISOLATION FOREST</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider" id="numAnomalies-input">
                     <CustomTooltipWrapper title={t("model.isolationforest.slider_1.info")}>
                        <p>{t("model.isolationforest.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.NumberOfAnomalies : numAnomalies}
                        setCurrentValue={setNumAnomalies}
                        defaultValue={20}
                        isPercentage={false}
                        disabled={readOnly}
                        min={0}
                        max={100}
                        id="numAnomalies"
                     />
                  </div>
                  <div className="model-slider" id="maxfeatures-input">
                     <CustomTooltipWrapper title={t("model.isolationforest.slider_2.info")}>
                        <p>{t("model.isolationforest.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0.0}
                        max={1.0}
                        defaultValue={1.0}
                        currentValue={readOnly ? parameters?.MaxFeatures : maxFeatures}
                        disabled={readOnly}
                        setCurrentValue={setMaxFeatures}
                        step={0.1}
                        id="maxfeatures"
                     />
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           if (randomState === false) setRandomState(!randomState);
                        }}
                     />
                     <CustomTooltipWrapper title={t("model.isolationforest.checkbox_1.info")}>
                        <p>{t("model.isolationforest.checkbox_1")}</p>
                     </CustomTooltipWrapper>
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           if (bootStrap === false) setBootStrap(!bootStrap);
                        }}
                     />
                     <CustomTooltipWrapper title={t("model.isolationforest.checkbox_2.info")}>
                        <p>{t("model.isolationforest.checkbox_2")}</p>
                     </CustomTooltipWrapper>
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           if (warmStart === false) setWarmStart(!warmStart);
                        }}
                     />
                     <CustomTooltipWrapper title={t("model.isolationforest.checkbox_3.info")}>
                        <p>{t("model.isolationforest.checkbox_3")}</p>
                     </CustomTooltipWrapper>
                  </div>
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown options={frameworkOptions} disabled={readOnly} />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        onClick={createIsolationForest}
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>
            {!model?.id ? (
               <div className="model-result-2">
                  {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               </div>
            ) : (
               <div className="model-result-3">
                  <div className="container-anomalies">
                     {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
                     <header style={{ textAlign: model?.saved ? "left" : "end" }}>
                        <p>{t("model.isolationforest.graphic_title")}</p>
                     </header>
                     <div className="anomalies-result">
                        {anomalies.map((anomalie, index) => {
                           return (
                              <Anomalies
                                 key={anomalie}
                                 number={index + 1}
                                 data={anomalie}
                                 showHistogram={selectedAnomalie === index + 1}
                                 onClick={() => {
                                    setSelectedAnomalie(selectedAnomalie === index + 1 ? 0 : index + 1);
                                 }}
                              />
                           );
                        })}
                     </div>
                  </div>
                  <div className="container-graphic" id="container"></div>
               </div>
            )}
         </div>

         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.metrics && model?.isCategorical && (
                  <button
                     disabled={!model}
                     onClick={() => {
                        setMetricsPopup(!metricsPopup);
                     }}
                  >
                     {t("model.footer.button_1")}
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     disabled={!model?.hasPrediction}
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     {t("model.footer.button_3")}
                  </button>
               )}
            </div>
         )}

         {model?.metrics && (
            <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
               <MetricsPopup
                  data={{
                     metrics: model?.metrics,
                     framework: model?.framework,
                     type: model?.type,
                  }}
               />
            </Popup>
         )}
         {/* <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
         <Prediction 
            targetName={model?.target}
            fields={model?.fields}
            modelId={model?.id}
            algorithmType={model?.type}
         />
         </Popup> */}
      </div>
   );
}

export default IsolationForest;
