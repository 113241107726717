import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/popups/actions/downloamodelpopup.css";

import DownloadIcon from "../../../assets/icons/jsxIcons/DownloadIcon";

import { Button } from "../../../components/Button";

import ws from "../../../services/wiselib";
import { Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const DownloadDatasetPopup = ({ setTrigger, modelResultId, cardType }) => {
   const { t } = useTranslation();

   const history = useHistory();

   const [isLoading, setIsLoading] = useState(false);

   const onChangeFile = async () => {
      const token = localStorage.getItem("user_token");
      setIsLoading(true);

      try {
         let response;
         if (cardType === "dataset") {
            response = await ws.downloaddataset(token, modelResultId);
         } else {
            response = await ws.downloaddatasource(token, modelResultId);
         }

         const blob = new Blob([response.data], { type: "text/csv" });

         const url = window.URL.createObjectURL(blob);

         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", "dataset.csv"); // Nome do arquivo

         document.body.appendChild(link);
         link.click();

         link.parentNode.removeChild(link);

         toast.success(t("popup.action.downloaddataset.success"));
      } catch (error) {
         handleErrorResponse(history, error, t);
      } finally {
         setIsLoading(false);
         setTrigger(false);
      }
   };

   return (
      <div id="download-model-popup">
         <div id="title-msg">{t("popup.action.downloaddataset.title")}</div>
         <div id="description"></div>
         {isLoading && <Loader active />}
         <div id="buttons">
            <div className="download">
               <Button
                  type="button"
                  buttonStyle="btn--icon--outline"
                  buttonSize="btn--auto"
                  disabled={isLoading}
                  onClick={() => {
                     onChangeFile();
                  }}
               >
                  <span id="download-icon">
                     <DownloadIcon style={{ color: "#22577A" }} />
                  </span>
                  {t("popup.action.downloaddataset.download")}
               </Button>
            </div>

            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  onClick={() => {
                     setTrigger(false);
                  }}
               >
                  {t("popup.action.downloaddataset.no")}
               </Button>
            </span>
         </div>
      </div>
   );
};
