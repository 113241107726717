import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React, { useEffect, useRef, useState } from "react";
import "../assets/css/components/userprofilepopup.css";

import { Popup } from "./Popup";

import { LogoutPopup } from "../views/popups/actions/LogoutPopup";
import { ProfileEditPopup } from "../views/popups/actions/ProfileEditPopup";
import { useSelector } from "react-redux";
import { selectUserPicture } from "../store/slices/user/userSlice";
import { useTranslation } from "react-i18next";
import "./../translation/i18n";

export const UserProfilePopup = ({
   userFirstName,
   userLastName,
   userEmail,
   userId,
   setEditProfilePopupTrigger,
}) => {
   const { t } = useTranslation();
   const link = "components.userprofile";
   const [editProfileTrigger, setEditProfileTrigger] = useState(false);
   const [logoutPopupTrigger, setLogoutPopupTrigger] = useState(false);

   const userPicture = useSelector(selectUserPicture);

   useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      // eslint-disable-next-line
   }, []);

   const refOne = useRef(null);
   const handleClickOutside = (e) => {
      if (refOne?.current !== null) {
         if (!refOne.current.contains(e.target)) {
            setEditProfilePopupTrigger();
         }
      }
   };

   return (
      <div id="userProfilePopup" ref={refOne}>
         <div className="user-profile-popup">
            <div className="user-profile-info">
               <div className="img-container">
                  {!userPicture ? (
                     <AccountCircleIcon style={{ fontSize: 32 }} />
                  ) : (
                     <img
                        id="user-profile-picture"
                        src={`${userPicture}`}
                        alt={<AccountCircleIcon style={{ fontSize: 32 }} />}
                     />
                  )}
               </div>
               <div className="user-profile-name" title={userFirstName + " " + userLastName}>
                  {userFirstName} {userLastName}
               </div>
               <div className="user-profile-email">{userEmail}</div>
            </div>
            <div className="user-profile-buttons">
               <div className="user-profile-button">
                  <button
                     className="button-cursor"
                     onClick={() => {
                        setEditProfileTrigger(true);
                     }}
                  >
                     {t(`${link}.edit`)}
                  </button>
               </div>
               <div className="user-profile-button">
                  <button
                     className="button-cursor"
                     onClick={() => {
                        setLogoutPopupTrigger(true);
                     }}
                  >
                     {t(`${link}.exit`)}
                  </button>
               </div>
               <div className="user-profile-button">
                  <div className="button-cursor" id="feedback-button">
                     <a
                        href="https://forms.office.com/r/EQ6QWAgETY"
                        target="_blank"
                        rel="noreferrer"
                     >
                        {t(`${link}.feedback`)}
                     </a>
                  </div>
               </div>
            </div>
            <Popup
               trigger={editProfileTrigger}
               setTrigger={setEditProfileTrigger}
            >
               <ProfileEditPopup setTrigger={setEditProfileTrigger} />
            </Popup>
            <Popup
               trigger={logoutPopupTrigger}
               setTrigger={setLogoutPopupTrigger}
               enableCloseIcon={true}
            >
               <LogoutPopup setTrigger={setLogoutPopupTrigger} />
            </Popup>
         </div>
      </div>
      // </ClickAwayListener>
   );
};
