import http from "../http-common";

/**
 * Busca um modelo pelo seu ID.
 *
 * @param {string} id - O ID do modelo a ser recuperado.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados do modelo.
 */
function getmodel(id) {
   return http
      .get(`/getmodel/${localStorage.getItem("user_token")}/${id}`)
      .then((response) => {
         return response.data;
      });
}

/**
 * Envia uma solicitação PATCH para salvar um modelo com o ID fornecido.
 *
 * @param {string} id - O ID do modelo a ser salvo.
 * @returns {Promise} - Uma promessa que resolve para a resposta da solicitação HTTP.
 */
function savemodel(id) {
   return http
      .patch("/savemodel", {
         token: localStorage.getItem("user_token"),
         id: id,
      })
      .then((response) => {
         return response;
      });
}

/**
 * Envia uma solicitação POST para criar e treinar um modelo de supportvectormachine
 *
 * @param {Object} params - Os parâmetros para o modelo de máquina de vetor de suporte.
 * @param {string} params.mlframework - O framework de machine learning a ser utilizado.
 * @param {string} params.kernel - O tipo de kernel para o SVM.
 * @param {string} params.datasetid - O ID do conjunto de dados a ser utilizado.
 * @param {string} params.name - O nome do modelo.
 * @param {number} params.trainfraction - A fração dos dados a serem usados para treinamento.
 * @param {string} params.target - A variável alvo para o modelo.
 * @param {Array<string>} params.featuresused - As características a serem usadas para o modelo.
 * @param {number} params.regparam - O parâmetro de regularização.
 * @param {number} params.numiterations - O número de iterações para o treinamento.
 * @returns {Promise<Object>} Os dados da resposta do servidor.
 */
function supportvectormachine({
   mlframework,
   kernel,
   datasetid,
   name,
   trainfraction,
   target,
   featuresused,
   regparam,
   numiterations,
}) {
   return http
      .post("/supportvectormachine", {
         mlframework: mlframework,
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         trainfraction: trainfraction,
         target: target,
         kernel: kernel,
         featuresused: featuresused,
         numiterations: numiterations,
         regparam: regparam,
      })
      .then((response) => {
         return response?.data;
      });
}

/**
 * Envia uma solicitação POST para o endpoint /isolationforest para criar um modelo de floresta de isolamento.
 *
 * @param {Object} params - Os parâmetros para o modelo de floresta de isolamento.
 * @param {string} params.datasetid - O ID do conjunto de dados a ser utilizado.
 * @param {string} params.name - O nome do modelo.
 * @param {Array<string>} params.featuresused - As características a serem usadas no modelo.
 * @param {number} params.numberofanomalies - O número de anomalias a serem detectadas.
 * @param {number} params.randomstate - O estado aleatório para reprodutibilidade.
 * @param {number} params.maxfeatures - O número máximo de características a serem usadas.
 * @param {boolean} params.bootstrap - Se deve usar amostras bootstrap.
 * @param {boolean} params.warmstart - Se deve reutilizar a solução da chamada anterior para ajustar.
 * @returns {Promise<Object>} Os dados da resposta do servidor.
 */
function isolationforest({
   datasetid,
   name,
   featuresused,
   numberofanomalies,
   randomstate,
   maxfeatures,
   bootstrap,
   warmstart,
}) {
   return http
      .post("/isolationforest", {
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         featuresused: featuresused,
         numberofanomalies: numberofanomalies,
         randomstate: randomstate,
         maxfeatures: maxfeatures,
         bootstrap: bootstrap,
         warmstart: warmstart,
      })
      .then((response) => {
         return response.data;
      });
}

/**
 * Envia uma solicitação POST para o endpoint /fpgrowth com os parâmetros fornecidos.
 *
 * @param {Object} params - Os parâmetros para o algoritmo FP-Growth.
 * @param {string} params.datasetid - O ID do conjunto de dados a ser utilizado.
 * @param {string} params.name - O nome para o modelo FP-Growth.
 * @param {number} params.minSupport - O limite mínimo de suporte.
 * @param {number} params.minConfidence - O limite mínimo de confiança.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta.
 */
function fpgrowth({ datasetid, name, minSupport, minConfidence }) {
   return http
      .post("/fpgrowth", {
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         minSupport: minSupport,
         minConfidence: minConfidence,
      })
      .then((response) => {
         return response?.data;
      });
}

/**
 * Função para treinar um modelo de perceptron multicamadas.
 *
 * @param {Object} params - Parâmetros para a função.
 * @param {string} params.mlframework - Framework de machine learning a ser utilizado.
 * @param {string} params.datasetid - ID do conjunto de dados a ser utilizado.
 * @param {string} params.name - Nome do modelo.
 * @param {number} params.trainfraction - Fração dos dados a serem usados para treinamento.
 * @param {string} params.target - Variável alvo para predição.
 * @param {Array<string>} params.featuresused - Lista de características a serem usadas.
 * @param {number} params.epoch - Número de épocas para o treinamento.
 * @param {Array<number>} params.hiddenLayers - Estrutura das camadas ocultas.
 * @param {string} params.activation - Função de ativação a ser utilizada.
 * @param {number} params.learningRate - Taxa de aprendizado.
 * @returns {Promise<Object>} - Retorna uma promessa que resolve com os dados da resposta.
 */
function multilayerperceptron({
   mlframework,
   datasetid,
   name,
   trainfraction,
   target,
   featuresused,
   epoch,
   hiddenLayers,
   activation,
   learningRate,
}) {
   return http
      .post("/multilayerperceptron", {
         token: localStorage.getItem("user_token"),
         mlframework: mlframework,
         datasetid: datasetid,
         name: name,
         trainfraction: trainfraction,
         target: target,
         featuresused: featuresused,
         epoch: epoch,
         hiddenLayers: hiddenLayers,
         activation: activation,
         learningRate: learningRate,
      })
      .then((response) => {
         return response?.data;
      });
}

/**
 * Exclui um modelo pelo seu ID.
 *
 * @param {string} id - O ID do modelo a ser excluído.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta.
 */
function deletemodel(id) {
   const token = localStorage.getItem("user_token");
   return http
      .request({
         method: "delete",
         url: "/deletemodel",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}}`,
         },
         data: { token: token, options: id },
      })
      .then((response) => response.data);
}

/**
 * Renomeia um modelo enviando uma solicitação PATCH para o servidor.
 *
 * @param {string} id - O ID do modelo a ser renomeado.
 * @param {Object} option - As novas opções para o modelo.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta.
 */
function renamemodel(id, option) {
   return http
      .patch("/renamemodel", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response?.data;
      });
}

async function getyoloparams(id) {
   const response = await http.get(
      `/getyoloparams/${localStorage.getItem("user_token")}/${id}`
   );
   return response.data;
}

/**
 * Busca a lista de usuários com quem o modelo especificado é compartilhado.
 *
 * @param {string} modelId - O ID do modelo.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta.
 */
function getsharedwith(modelId) {
   return http
      .get(`/getsharedwith/${localStorage.getItem("user_token")}/${modelId}`)
      .then((response) => response.data);
}

/**
 * Busca usuários sugeridos para um determinado modelo.
 *
 * @param {string} modelId - O ID do modelo para o qual obter usuários sugeridos.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta contendo os usuários sugeridos.
 */
function getsuggestedusers(modelId) {
   return http
      .get(
         `/getsuggestedusers/${localStorage.getItem("user_token")}/${modelId}`
      )
      .then((response) => response.data);
}

/**
 * Remove um usuário da lista de compartilhamento de um modelo específico.
 *
 * @param {string} modelId - O ID do modelo.
 * @param {string} email - O email do usuário a ser removido.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados da resposta.
 */
function removeusersharedwith(modelId, email) {
   return http
      .patch(
         `/removeusersharedwith/${localStorage.getItem(
            "user_token"
         )}/${modelId}/${email}`
      )
      .then((response) => response.data);
}

/**
 * Recupera todas as métricas dos modelos de um conjunto de dados.
 *
 * @param {string} datasetId - O ID do conjunto de dados.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados retornados pelo servidor.
 */
function getAllModelsMetricsFromDataset(datasetId) {
   return http
      .get(
         `/getAllModelsMetricsFromDataset/${localStorage.getItem(
            "user_token"
         )}/${datasetId}`
      )
      .then((response) => response.data);
}

/**
 * Baixa um subdataset de um cluster selecionado.
 *
 * @param {string} modelId - O ID do modelo.
 * @param {number} clusterNumber - O número do cluster.
 * @param {string} framework - O framework utilizado.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados do cluster.
 */
function getClusterData(modelId, clusterNumber, framework) {
   return http.post('/getclusterdata', {
      token: localStorage.getItem("user_token"),
      id: modelId,
      options: [framework, clusterNumber],
   }).then((response) => response.data);
}

/**
 * Baixa os dados de um nó específico em um modelo de árvore de decisão.
 *
 * @param {string} modelId - O ID do modelo.
 * @param {Object} node - O nó para o qual os dados devem ser baixados.
 * @returns {Promise<Object>} Uma promessa que resolve para os dados do nó especificado.
 */
function downloadNodeDT(modelId, node) {
   return http.post('/getnodedata', {
      token: localStorage.getItem("user_token"),
      id: modelId,
      options: [node],
   }).then((response) => response.data);
}

const modelService = {
   getmodel,
   getyoloparams,
   savemodel,
   supportvectormachine,
   isolationforest,
   fpgrowth,
   multilayerperceptron,
   deletemodel,
   renamemodel,
   getsharedwith,
   getsuggestedusers,
   removeusersharedwith,
   getAllModelsMetricsFromDataset,
   getClusterData,
   downloadNodeDT
};

export default modelService;
