import React from "react";
import "../assets/css/components/card.css";
import SvgDatasetCSV from "../assets/icons/jsxIcons/DatasetCSVIcon";
import SvgDatasetIMG from "../assets/icons/jsxIcons/DatasetIMGIcon";

import ClockIcon from "../assets/icons/jsxIcons/ClockIcon";

import { MoreOptions } from "./MoreOptions";

import dp from "../helpers/data_parse";

import { useTranslation } from "react-i18next";
import "./../translation/i18n";

import { getAlgorithmIcon } from "../helpers/getAlgorithmIcon";
import { Share } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../store/slices/user/userSlice";

export const Card = ({
   type,
   algorithm,
   name,
   description,
   date,
   elementId,
   isShared,
   datasets,
   models,
   projects,
   direction,
   isSharedProject,
   dataType = "document",
   ownerEmail,
   sharedWith,
   onClick,
   selectMode,
   selected
}) => {
   const { t } = useTranslation();

   const userEmail = useSelector(selectUserEmail);

   sharedWith = sharedWith?.filter((email) => email !== userEmail);

   function getDatasetIcon(type) {
      if (type === "document") {
         return <SvgDatasetCSV style={{ size: 62, color: "#38A3A5" }} />;
      } else if (type === "image") {
         return <SvgDatasetIMG style={{ size: 62, color: "#38A3A5" }} />;
      }
      return <SvgDatasetIMG style={{ size: 62, color: "#282828" }} />;
   }

   const link = "components.card";

   const directionValue = direction || (window.innerWidth > 450 ? direction : "top right");

   const renderProjectItems = (projects) => {
      if (projects && projects.length > 0) {
         return projects.map((project, index) => (
            <span key={project}>
               {project} {index !== projects.length - 1 && " | "}
            </span>
         ));
      } else if (projects !== undefined) {
         return <span>{t(`${link}.none`)}</span>;
      }
      return null;
   };

   const renderProjects = renderProjectItems(projects);

   const cardDescription = !(isSharedProject || isShared) ? description : t(`${link}.description`);

   const renderProjectCard = () => (
      <div className="project-card">
         <div className="CardHeader">
            <div id="title">{name}</div>
            {!isSharedProject && <MoreOptions cardName={name} cardtype={type} elementId={elementId} />}
         </div>
         <div className="Description">{cardDescription}</div>
         <div className="Info">
            {!isSharedProject && (
               <div>
                  <div id="number">{datasets}</div>
                  <div>{t(`${link}.dataset`)}</div>
               </div>
            )}
            <div>
               <div id="number">{models}</div>
               <div>{t(`${link}.model`)}</div>
            </div>
         </div>
         <span className="LastUsed">
            <span id="icon">
               <ClockIcon style={{ size: 20, color: "red" }} />
            </span>
            <span id="text">{dp.epochdateparse(date)}</span>
         </span>
      </div>
   );

   const renderDatasetCard = () => (
      <button className="dataset-card" onClick={onClick}>
         {selectMode && <input type="checkbox" checked={selected} onChange={onClick} className="dataset-checkbox" />}
         <div className="dataset-card-content">
            <div className="dataset-card-icon">{getDatasetIcon(dataType)}</div>
            <div className="dataset-card-info">
               <header>
                  <p>{name}</p>
                  <MoreOptions
                     cardName={name}
                     cardtype={type}
                     elementId={elementId}
                     direction={directionValue}
                     projects={projects}
                  />
               </header>
               <div className="dataset-card-date">
                  <ClockIcon style={{ size: 20 }} />
                  <p>{dp.epochdateparse(date)}</p>
               </div>
            </div>
         </div>
         <div className="dataset-card-link">
            <div className="dataset-card-projects">
               <p>{renderProjects}</p>
            </div>
         </div>
      </button>
   );

   const renderModelCard = () => (
      <button className="model-card">
         <div className="model-card-icon">{getAlgorithmIcon(algorithm)}</div>
         <div className="model-card-info">
            <header>
               <p>{name == null || name === undefined ? algorithm : name}</p>
               <MoreOptions
                  cardName={name}
                  cardtype={type}
                  elementId={elementId}
                  direction={directionValue}
                  projects={projects}
               />
            </header>

            {isShared && ownerEmail !== "" && (
               <p id="model-owner">
                  <span id="owner-email">
                     {t(`${link}.create`) + " "}
                     {ownerEmail}
                  </span>
               </p>
            )}
            {sharedWith && sharedWith.length > 0 && (
               <p id="model-shared">
                  <Share />
                  <span id="shared-with" title={sharedWith}>
                     {sharedWith.join(" | ")}
                  </span>
               </p>
            )}

            <div className="model-card-date">
               <ClockIcon style={{ size: 20 }} />
               <p>{dp.epochdateparse(date)}</p>
            </div>
         </div>
      </button>
   );

   const renderCard = () => {
      switch (type) {
         case "project":
            return renderProjectCard();
         case "dataset":
         case "datasource":
         case "datasourcewiseml":
            return renderDatasetCard();
         case "model":
         case "shared-model":
            return renderModelCard();
         default:
            return;
      }
   };

   return <>{renderCard()}</>;
};
